import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/MDButton";

function DialogConfirmation({
  open,
  onClose,
  title = "Confirmar ação",
  description = "Você tem certeza que deseja realizar essa ação?",
  confirmText = "Confirmar",
  colorCancel = "error",
  colorConfirm = "success",
  confirmAction = () => {},
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <MDButton variant="outlined" color={colorCancel} onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton variant="contained" color={colorConfirm} onClick={confirmAction}>
          {confirmText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirmation;
