import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { sortBy } from "lodash";

// Images
import MenuButton from "components/MenuButton";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

export default function data(events, destroy, openEdit) {
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  function Job({ title, description }) {
    return (
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption">{description}</MDTypography>
      </MDBox>
    );
  }

  return {
    columns: [
      { Header: "Título", accessor: "title", align: "left" },
      { Header: "Autor", accessor: "author", align: "left" },
      { Header: "Data de início", accessor: "startDate", align: "left" },
      { Header: "Data de fim", accessor: "endDate", align: "left" },
      { Header: "Participantes", accessor: "participators", align: "left" },
      { Header: "Status", accessor: "status", width: "10%", align: "center" },
      { Header: "Ações", accessor: "action", align: "right" },
    ],
    rows: sortBy(events, ["ended", -1])?.map((event) => ({
      title: <Job title={event?.name} />,
      author: <Job title={event?.user?.name} />,
      startDate: (
        <Job title={new Date(event?.startDate).toLocaleDateString("pt-br", dateOptions)} />
      ),
      endDate: <Job title={new Date(event?.endDate)?.toLocaleDateString("pt-br", dateOptions)} />,
      participators: <Job title={event?.participators} />,
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={
              event?.ended ? "Finalizado" : event?.ongoing ? "Em andamento" : "Pendente"
            }
            color={event?.ended ? "error" : event?.ongoing ? "warning" : "info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <MenuButton>
          <MDButton
            onClick={() => openEdit(event?.id)}
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Editar
          </MDButton>
          <MDButton
            onClick={() => destroy(event)}
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Excluir
          </MDButton>
        </MenuButton>
      ),
      key: event?.id,
    })),
  };
}
