import { useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";

import MDButton from "components/MDButton";

import useAuth from "hooks/useAuth";

import service from "service";
import ConfirmationForm from "components/ConfirmationForm";

function CastleSiegeConfirmation({ setOpen, open, setError }) {
  const { characters, user, setUser } = useAuth();
  const [loading, setLoading] = useState();

  const onClose = () => setOpen(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await service.castleSiege.confirm({ data });
      setUser((oldUser) => ({
        ...oldUser,
        csConfirmed: true,
        confirmations: (user.confirmations || 0) + 1,
      }));
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Confirmar presença</DialogTitle>
      <Divider />
      <DialogContent>
        <ConfirmationForm
          characters={characters}
          user={user}
          onSubmit={onSubmit}
          formId="confirm-mine"
        />
      </DialogContent>
      <DialogActions>
        <MDButton disabled={loading} variant="outlined" color="error" onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton
          disabled={loading}
          variant="contained"
          color="success"
          type="submit"
          form="confirm-mine"
        >
          Confirmar presença
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CastleSiegeConfirmation;
