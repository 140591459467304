import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import LoadingBackdrop from "components/LoadingBackdrop";

import CoverLayout from "layouts/authentication/components/CoverLayout";
import { CheckCircle, Error } from "@mui/icons-material";

import helpers from "helpers";

import { nameRoutes } from "routes";

import bg4 from "assets/images/bg4.jpg";

import service from "service";
import schema from "./schema";

function Cover() {
  const navigate = useNavigate(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phone: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    service.users
      .register({ user: data })
      .then(() => {
        setSuccess(true);
        reset();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(
          err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
        );
      });
  };

  return (
    <CoverLayout image={bg4}>
      <LoadingBackdrop open={loading} />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Junte-se à nós.
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Faça seu cadastro abaixo
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Nome e Sobrenome"
                    variant="standard"
                    placeholder="Ex: José Silva"
                    helperText={errors?.name?.message}
                    error={!!errors?.name}
                    fullWidth
                  />
                )}
                name="name"
                control={control}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="email"
                    label="E-mail"
                    variant="standard"
                    helperText={errors?.email?.message}
                    error={!!errors?.email}
                    fullWidth
                  />
                )}
                name="email"
                control={control}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Whatsapp"
                    variant="standard"
                    helperText={errors?.phone?.message}
                    onChange={(e) => {
                      e.target.value = helpers.phoneMask(e?.target?.value);
                      field.onChange(e);
                      return e;
                    }}
                    error={!!errors?.phone}
                    fullWidth
                  />
                )}
                name="phone"
                control={control}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Senha"
                    variant="standard"
                    helperText={errors?.password?.message}
                    error={!!errors?.password}
                    fullWidth
                  />
                )}
                name="password"
                control={control}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Confirme sua senha"
                    variant="standard"
                    color="white"
                    error={!!errors?.passwordConfirm}
                    helperText={errors?.passwordConfirm?.message}
                    fullWidth
                  />
                )}
                name="passwordConfirm"
                control={control}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Cadastrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Já possui uma conta?{" "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Fazer login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        open={!!error}
        close={() => {
          navigate(nameRoutes.home);
          setError(false);
        }}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
      <MDSnackbar
        open={success}
        close={() => setSuccess(false)}
        icon={<CheckCircle color="white" />}
        color="success"
        title="Sucesso!"
        content="Cadastro realizado com sucesso! Aguarde até que algum moderador ative a sua conta."
      />
    </CoverLayout>
  );
}

export default Cover;
