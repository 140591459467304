import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import personParadox from "assets/images/person_paradox.png";
import MenuButton from "components/MenuButton";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

export default function data(confirmations, destroy, openView, confirmMeta) {
  function Author({ image, id, name, email }) {
    return (
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{ cursor: "pointer" }}
        onClick={() => openView(id)}
      >
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function Job({ title, description }) {
    return (
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption">{description}</MDTypography>
      </MDBox>
    );
  }

  return {
    columns: [
      { Header: "Membro", accessor: "member", align: "left" },
      { Header: "Personagem de UP", accessor: "up", width: "25%", align: "left" },
      { Header: "Personagem de BOSS", accessor: "boss", width: "25%", align: "left" },
      { Header: "Meta", accessor: "meta", width: "10%", align: "center" },
      { Header: "Ações", accessor: "action", align: "right" },
    ],
    rows: confirmations?.map((confirmation) => ({
      member: (
        <Author
          id={confirmation?.user?.id}
          image={personParadox}
          name={confirmation?.user?.name}
          email={confirmation?.user?.profile?.name}
        />
      ),
      up: <Job title={confirmation?.up?.name} />,
      boss: <Job title={confirmation?.boss?.name ?? "Não informado"} />,
      meta: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={confirmation?.user?.meta ? "OK" : "Pendente"}
            color={confirmation?.user?.meta ? "success" : "info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <MenuButton>
          <MDButton
            onClick={() => openView(confirmation?.user?.id)}
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Ver usuário
          </MDButton>
          {!confirmation?.user?.meta && (
            <MDButton
              onClick={() => confirmMeta(confirmation?.user?.id)}
              variant="text"
              size="small"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Confirmar meta
            </MDButton>
          )}
          <MDButton
            onClick={() => destroy(confirmation)}
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Excluir
          </MDButton>
        </MenuButton>
      ),
      key: confirmation?.id,
    })),
  };
}
