import { Error } from "@mui/icons-material";

import { useState } from "react";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDSnackbar from "components/MDSnackbar";
import LoadingBackdrop from "components/LoadingBackdrop";

import EventForm from "../components/EventForm";

function NewEvent() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();

  return (
    <DashboardLayout>
      <LoadingBackdrop open={loading} />
      <DashboardNavbar />
      <EventForm setLoading={setLoading} setError={setError} />
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </DashboardLayout>
  );
}

export default NewEvent;
