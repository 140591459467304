import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const MEMBER_ROLE = 0;
const MOD_ROLE = 1;
const GM_ROLE = 2;
const ADM_ROLE = 3;

const PROFILES = [
  {
    role: MEMBER_ROLE,
    name: "Membro",
  },
  {
    role: MOD_ROLE,
    name: "Moderador",
  },
  {
    role: GM_ROLE,
    name: "Guild Master",
  },
  {
    role: ADM_ROLE,
    name: "Administrador",
  },
];

const DATA_SOCIAL = {
  facebook: {
    icon: <FacebookIcon />,
    color: "facebook",
  },
  youtube: {
    icon: <YouTubeIcon />,
    color: "youtube",
  },
  instagram: {
    icon: <InstagramIcon />,
    color: "instagram",
  },
};

const users = {
  MEMBER_ROLE,
  DATA_SOCIAL,
  MOD_ROLE,
  GM_ROLE,
  ADM_ROLE,
  PROFILES,
};

export default users;
