import { useEffect, useState } from "react";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import { reverse } from "named-urls";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

import { useMaterialUIController } from "context";

import MDButton from "components/MDButton";
import useAuth from "hooks/useAuth";
import LoadingBackdrop from "components/LoadingBackdrop";

import { nameRoutes } from "routes";

import service from "service";
import constants from "helpers/constants";

import topicsTableData from "./data/topicsTableData";

function Tables() {
  const [controller] = useMaterialUIController();
  const navigate = useNavigate();
  const { loaded } = useAuth();

  const { sidenavColor } = controller;
  const { permissions } = useAuth();
  const [loading, setLoading] = useState();
  const [topics, setTopics] = useState();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await service.topics.list();

      setTopics(response?.data);
      setLoading(false);
    };

    if (loaded) {
      loadData();
    }
  }, [loaded]);

  const goToTopic = (topicId) => {
    navigate(reverse("/forum/:topicId", { topicId }));
  };

  const { columns, rows } = topicsTableData(
    filter(topics, { category: { id: constants.topics.LAND_CATEGORY } }),
    goToTopic
  );
  const { columns: columnsCs, rows: rowsCs } = topicsTableData(
    filter(topics, { category: { id: constants.topics.CS_CATEGORY } }),
    goToTopic
  );

  const handleNew = () => navigate(nameRoutes.topics.new);

  return (
    <DashboardLayout>
      <LoadingBackdrop open={loading} />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="lg"
                bgColor={sidenavColor}
                coloredShadow={sidenavColor}
              >
                <MDTypography variant="h6" color="white">
                  Land of Trials
                </MDTypography>
                {permissions?.create_topic && (
                  <MDButton size="small" variant="outlined" onClick={handleNew}>
                    Novo tópico
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
              >
                <MDTypography variant="h6" color="white">
                  Castle siege
                </MDTypography>
                {permissions?.create_topic && (
                  <MDButton size="small" variant="outlined" onClick={handleNew}>
                    Novo tópico
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: columnsCs, rows: rowsCs }}
                  isSorted
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
