import { useState } from "react";

import Menu from "@mui/material/Menu";

import MDBox from "components/MDBox";

import { IconButton, Tooltip } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

function MenuButton({ children, tooltipText }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={tooltipText || "Ações"}>
        <IconButton
          id="basic-button"
          aria-controls={open ? "menu-button" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert color="white" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-button"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   style: {
        //     width: "20ch",
        //   },
        // }}
      >
        <MDBox px={0.5} gap={1} display="flex" alignItems="center" flexDirection="column">
          {children}
        </MDBox>
      </Menu>
    </>
  );
}

export default MenuButton;
