const phoneMask = (value) => {
  const r = value.replace(/\D/g, "");
  let v = r.replace(/^0/, "");

  if (!v) return "";

  if (v.length > 11) {
    return v.replace(/^(\d{1,4})(\d\d)(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4");
  }

  v = v.replace(/(\d{2})(\d)/, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");

  return v;
};

export default phoneMask;
