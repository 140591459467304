const mountYoutubeMedia = (url) => {
  if (!url) return null;

  const parsedUrl = new URL(url);

  const videoId = parsedUrl?.searchParams?.get("v");

  if (!videoId) return null;

  return {
    video: true,
    url: `https://www.youtube.com/embed/${videoId}`,
  };
};

export default mountYoutubeMedia;
