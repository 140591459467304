import { useEffect } from "react";

import { isEmpty } from "lodash";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "components/Tables/DataTable";
import useAdmin from "hooks/useAdmin";

import { useMaterialUIController } from "context";

import service from "service";

import topicsData from "./data/topicsData";

function Topics() {
  const { topics, loadTopics, setLoading } = useAdmin();
  const destroy = async (topic) => {
    try {
      setLoading(true);
      await service.topics.destroy(topic?.id);
      await loadTopics();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { columns, rows } = topicsData(topics ?? [], destroy);
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    if (isEmpty(topics)) {
      loadTopics();
    }
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="sm"
              bgColor={sidenavColor}
              coloredShadow={sidenavColor}
            >
              <MDTypography variant="h6" color="white">
                Todos os tópicos
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable table={{ columns, rows }} noEndBorder />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Topics;
