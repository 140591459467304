import api from "./api";

const show = async () => {
  const response = await api.get("/position");

  return response;
};

const draft = async () => {
  const response = await api.get("/position/draft");

  return response;
};

const create = async (data) => {
  const response = await api.post("/position", data);

  return response;
};

const update = async (positionId, data) => {
  const response = await api.put(`/position/${positionId}`, data);

  return response;
};

const publish = async (positionId) => {
  const response = await api.post(`/position/${positionId}/publish`);

  return response;
};

const reset = async () => {
  const response = await api.put(`/position/reset`);

  return response;
};

const confirmation = {
  show,
  draft,
  create,
  update,
  publish,
  reset,
};

export default confirmation;
