import { useEffect, useState } from "react";

import { find, isEmpty } from "lodash";
import { Refresh } from "@mui/icons-material";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import MDButton from "components/MDButton";

import useAdmin from "hooks/useAdmin";

import ViewUser from "layouts/panel/components/ViewUser";

import { useMaterialUIController } from "context";
import service from "service";

import confirmablesData from "./data/confirmablesData";

function Tables() {
  const [openView, setOpenView] = useState();

  const { users, upConfirmations, loadUpConfirmations, setLoading } = useAdmin();
  const [user, setUser] = useState();
  const viewUser = (userId) => {
    const currentUser = find(users, { id: userId });
    setUser(currentUser);
    setOpenView(true);
  };

  const confirmMeta = async (userId) => {
    try {
      setLoading(true);
      await service.users.confirmMeta(userId);
      await loadUpConfirmations();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const destroy = async (confirmation) => {
    try {
      setLoading(true);
      await service.upConfirmation.destroy(confirmation?.id);
      await loadUpConfirmations();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { columns, rows } = confirmablesData(upConfirmations ?? [], destroy, viewUser, confirmMeta);
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    if (isEmpty(upConfirmations)) {
      loadUpConfirmations();
    }
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="sm"
              bgColor={sidenavColor}
              coloredShadow={sidenavColor}
            >
              <MDTypography variant="h6" color="white">
                Lista de UP informados pelos membros
              </MDTypography>
              <MDButton
                size="small"
                startIcon={<Refresh />}
                variant="outlined"
                onClick={loadUpConfirmations}
              >
                Atualizar
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      {openView && <ViewUser open setOpen={setOpenView} setError={() => {}} user={user} />}
    </MDBox>
  );
}

export default Tables;
