import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";
import NotFound from "layouts/errors/not-found";

import Events from "layouts/events";
import NewEvent from "layouts/events/new-event";
import ShowEvent from "layouts/events/show-event";
import Forum from "layouts/forum";
import NewTopic from "layouts/forum/new-topic";
import Characters from "layouts/characters";
import Users from "layouts/users";
import CharactersStep from "layouts/authentication/character-step";
import ShowTopic from "layouts/forum/show-topic";
import Panel from "layouts/panel";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { include } from "named-urls";

import Icon from "@mui/material/Icon";
import {
  GiCalendar,
  GiCharacter,
  GiChatBubble,
  GiExitDoor,
  GiHouse,
  GiSwordwoman,
  GiTwoShadows,
} from "react-icons/gi";

import service from "service";

export const nameRoutes = {
  home: "/",
  signUp: "/sign-up",
  resetPassword: "/reset-password/:userId/:token",
  characters: "/characters",
  charactersStep: "/characters-step",
  profile: "/profile",
  panel: "/panel",
  signOut: "/authentication/sign-out",
  forum: "/forum",
  users: "/members",
  topics: include("/forum", {
    new: "new",
    show: ":topicId",
  }),
  events: include("/eventos", {
    all: "",
    new: "new",
    show: ":eventId",
  }),
};

function LogOut() {
  const navigate = useNavigate();
  useEffect(() => {
    service.authentication.logOut();
    navigate(nameRoutes.home);
  }, []);
  return <>.</>;
}

const routes = [
  {
    type: "collapse",
    name: "Início",
    key: "home",
    icon: <GiHouse size={20} />,
    route: nameRoutes.home,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    icon: <GiCharacter size={20} />,
    route: nameRoutes.profile,
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Fórum",
    key: "forum",
    icon: <GiChatBubble size={20} />,
    route: nameRoutes.forum,
    component: <Forum />,
  },
  {
    type: "collapse",
    name: "Eventos",
    key: "eventos",
    icon: <GiCalendar size={20} />,
    route: nameRoutes.events.all,
    component: <Events />,
  },
  {
    name: "Novo evento",
    key: "new-topic",
    route: nameRoutes.events.new,
    component: <NewEvent />,
  },
  {
    name: "Editar evento",
    key: "new-topic",
    route: nameRoutes.events.show,
    component: <ShowEvent />,
  },
  {
    name: "Novo tópico",
    key: "new-topic",
    route: nameRoutes.topics.new,
    component: <NewTopic />,
  },
  {
    name: "Ver tópico",
    key: "show-topic",
    route: nameRoutes.topics.show,
    component: <ShowTopic />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: nameRoutes.home,
    kind: "auth",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: nameRoutes.signUp,
    kind: "auth",
    component: <SignUp />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: nameRoutes.resetPassword,
    kind: "auth",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    name: "Personagens",
    key: "characters",
    icon: <GiSwordwoman size={20} />,
    route: nameRoutes.characters,
    component: <Characters />,
  },
  {
    name: "Painel Administrativo",
    key: "panel",
    route: nameRoutes.panel,
    component: <Panel />,
  },
  {
    name: "Cadastrar Personagens",
    key: "characters-step",
    icon: <Icon fontSize="small">engineering</Icon>,
    route: nameRoutes.charactersStep,
    component: <CharactersStep />,
  },
  {
    type: "collapse",
    name: "Membros",
    key: "users",
    icon: <GiTwoShadows size={20} />,
    route: nameRoutes.users,
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Sair",
    key: "sign-out",
    icon: <GiExitDoor size={20} />,
    route: nameRoutes.signOut,
    component: <LogOut />,
  },
  {
    key: "not-found",
    kind: "global",
    route: "/not-found",
    component: <NotFound />,
  },
];

export default routes;
