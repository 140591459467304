import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { Link } from "react-router-dom";

import personParadox from "assets/images/person_paradox.png";
import { reverse } from "named-urls";

export default function data(topics, goToTopic) {
  function Author({ image, name, email }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function Job({ title, description, id }) {
    return (
      <MDBox
        width="100%"
        lineHeight={1}
        textAlign="left"
        sx={{ cursor: "pointer" }}
        onClick={() => goToTopic(id)}
      >
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption">{description}</MDTypography>
      </MDBox>
    );
  }

  return {
    columns: [
      { Header: "Título", accessor: "title", width: "45%", align: "left", isSorted: true },
      { Header: "Autor", accessor: "author", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Data", accessor: "date", align: "center" },
      { Header: "Ações", accessor: "action", align: "center" },
    ],

    rows: topics?.map((topic) => ({
      title: <Job id={topic?.id} title={topic?.name} description={topic?.description} />,
      author: (
        <Author image={personParadox} name={topic?.user?.name} email={topic?.user?.profile?.name} />
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent="Ativo" color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      date: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {new Date(topic?.createdAt).toLocaleString()}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component={Link}
          to={reverse("/forum/:topicId", { topicId: topic?.id })}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Ver
        </MDTypography>
      ),
      key: topic?.id,
    })),
  };
}
