import { useContext } from "react";

import AdminContext from "contexts/AdminContext";

const useAdmin = () => {
  const adminContext = useContext(AdminContext);

  if (adminContext === undefined) {
    throw new Error("useAdmin should beeing inside a AdminProvider");
  }

  return {
    users: adminContext.users,
    usersInactive: adminContext.usersInactive,
    usersBanned: adminContext.usersBanned,
    loaded: adminContext.loaded,
    loadData: adminContext.loadData,
    loading: adminContext.loading,
    setLoading: adminContext.setLoading,
    confirmations: adminContext.confirmations,
    confirmables: adminContext.confirmables,
    topics: adminContext.topics,
    events: adminContext.events,
    upConfirmations: adminContext.upConfirmations,
    loadLastList: adminContext.loadLastList,
    loadConfirmations: adminContext.loadConfirmations,
    loadUpConfirmations: adminContext.loadUpConfirmations,
    loadTopics: adminContext.loadTopics,
    loadEvents: adminContext.loadEvents,
  };
};

export default useAdmin;
