import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { Error } from "@mui/icons-material";

import MDSnackbar from "components/MDSnackbar";

import PositionContext from "contexts/PositionContext";
import useAdmin from "hooks/useAdmin";
import service from "service";

function PositionProvider({ children }) {
  const { confirmations, loading: loadingAdmin } = useAdmin();

  const adminConfirmations = useMemo(() => confirmations, [loadingAdmin]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [changed, setChanged] = useState(false);

  const [draft, setDraft] = useState({});
  const [defenseState, setDefenseState] = useState({});
  const [attackState, setAttackState] = useState({});
  const [openAddPosition, setOpenAddPosition] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);

  const resetState = () => {
    setCurrentPosition(0);
    setDraft({});
    setDefenseState({});
    setAttackState({});
    setChanged(false);
  };

  const loadDraft = async () => {
    setLoading(true);
    const response = await service.position.draft();

    if (response?.data) {
      const { defense, attack, ...draftData } = response.data;

      setDraft(draftData ?? {});
      setDefenseState(defense ?? {});
      setAttackState(attack ?? {});
    }

    setChanged(false);
    setLoading(false);
  };

  const apiAction = async (action) => {
    try {
      setLoading(true);
      const response = await action();
      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
      return err;
    }
  };

  const createDraft = () =>
    apiAction(async () =>
      service.position
        .create({ data: { attack: attackState, defense: defenseState } })
        .then((response) => {
          setDraft({ id: response?.data?.token });
          setChanged(false);
          return response?.data?.token;
        })
    );

  const publish = () => {
    apiAction(async () => service.position.publish(draft?.id)).then((response) => {
      setDraft(response?.data);
      setChanged(false);
    });
  };

  const update = () =>
    apiAction(async () =>
      service.position
        .update(draft?.id, { data: { attack: attackState, defense: defenseState } })
        .then((response) => {
          setDraft(response?.data);
          setChanged(false);
        })
    );

  const reset = () => apiAction(async () => service.position.reset().then(() => resetState()));

  useEffect(() => {
    if (!loaded && !isEmpty(confirmations)) {
      loadDraft().then(() => setLoaded(true));
    }
  }, [confirmations]);

  return (
    <PositionContext.Provider
      value={useMemo(
        () => ({
          attackState,
          confirmations: adminConfirmations,
          currentPosition,
          defenseState,
          draft,
          loading,
          openAddPosition,
          changed,
          createDraft,
          publish,
          update,
          reset,
          setLoading,
          setAttackState,
          setChanged,
          setCurrentPosition,
          setDefenseState,
          setOpenAddPosition,
        }),
        [
          loading,
          defenseState,
          attackState,
          openAddPosition,
          currentPosition,
          adminConfirmations,
          changed,
          draft,
        ]
      )}
    >
      {children}
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </PositionContext.Provider>
  );
}

export default PositionProvider;
