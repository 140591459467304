import { GiBoltShield, GiCrown, GiGuards, GiMegaphone, GiStarFormation } from "react-icons/gi";

const ICON_TAGS = {
  GM: <GiCrown size={22} />,
  "Vice GM": <GiGuards size={22} />,
  BM: <GiBoltShield size={22} />,
  "Voz Global": <GiMegaphone size={22} />,
  Líder: <GiStarFormation size={22} />,
};

const positions = {
  ICON_TAGS,
};

export default positions;
