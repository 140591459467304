const select = {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },
  },

  icon: {
    color: "#FFF !important",
  },
};

export default select;
