import { forwardRef } from "react";

import PropTypes from "prop-types";

import { FormControl, InputLabel } from "@mui/material";
import MDInputRoot from "./MDInputRoot";

const MDSelect = forwardRef(({ error, success, disabled, label, ...rest }, ref) => (
  <FormControl fullWidth>
    <InputLabel
      id="demo-simple-select-helper-label"
      sx={
        rest.variant === "standard"
          ? {
              top: "8px",
              left: "-13px",
            }
          : {
              backgroundColor: (theme) => theme.palette.background.default,
              px: 1,
            }
      }
    >
      {label}
    </InputLabel>
    <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
  </FormControl>
));

MDSelect.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDSelect.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDSelect;
