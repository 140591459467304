import { useState } from "react";

import { BookmarkAdd, Close } from "@mui/icons-material";
import { IconButton, Menu, Tooltip } from "@mui/material";

import usePosition from "hooks/usePosition";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import constants from "helpers/constants";

const { ICON_TAGS } = constants.positions;

function AddTag({ addTag }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MDBox>
      <Tooltip title="Gerenciar missões">
        <IconButton
          aria-controls={open ? "add-tag" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="default"
        >
          <BookmarkAdd />
        </IconButton>
      </Tooltip>
      <Menu
        id="add-tag"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MDBox px={0.2} gap={1} display="flex" alignItems="center">
          {Object.keys(ICON_TAGS).map((tag) => (
            <Tooltip title={tag} key={tag}>
              <IconButton color="secondary" onClick={() => addTag(tag)}>
                {ICON_TAGS[tag]}
              </IconButton>
            </Tooltip>
          ))}
        </MDBox>
      </Menu>
    </MDBox>
  );
}

function CardCharacter({ color, positionName, data }) {
  const { removeCharacter, addTag } = usePosition();

  return (
    <MDBox
      width="100%"
      p={1}
      variant="gradient"
      display="flex"
      shadow="sm"
      alignItems="center"
      justifyContent="space-between"
      bgColor={color}
    >
      <MDBox>
        <MDTypography variant="h6" color="white">
          {data?.character?.name}
        </MDTypography>
        <MDTypography variant="h6" color="white">
          {data?.position}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center" gap={2}>
        <MDBox display="flex" alignItems="center" gap={1}>
          {data?.tags?.map((tag) => (
            <Tooltip key={tag} title={tag}>
              <MDBox display="flex" alignItems="center" sx={{ color: "white !important" }}>
                {ICON_TAGS[tag]}
              </MDBox>
            </Tooltip>
          ))}
        </MDBox>
        <AddTag addTag={(tagName) => addTag(positionName, data?.id, tagName)} />

        <Tooltip title="Remover">
          <IconButton>
            <Close color="error" onClick={() => removeCharacter(positionName, data?.id)} />
          </IconButton>
        </Tooltip>
      </MDBox>
    </MDBox>
  );
}

export default CardCharacter;
