import { useMemo, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import DataTableHeadCell from "components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Tables/DataTable/DataTableBodyCell";
import { filter, includes, isNil } from "lodash";

function DataTable({ canSearch, table, searchText = "Buscar" }) {
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const [search, setSearch] = useState("");

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {canSearch && (
        <MDBox width="20%" ml={2}>
          <MDInput
            placeholder={searchText}
            value={search}
            size="small"
            fullWidth
            onChange={(e) => {
              setSearch(e?.target?.value);
            }}
          />
        </MDBox>
      )}
      <Table>
        <MDBox component="thead">
          <TableRow>
            {columns.map(({ Header, ...props }) => (
              <DataTableHeadCell key={Header} {...(props ?? {})}>
                {Header}
              </DataTableHeadCell>
            ))}
          </TableRow>
        </MDBox>
        <TableBody>
          {filter(
            data,
            (target) =>
              isNil(target?.searchable) ||
              includes(target?.searchable?.toLowerCase(), search?.toLowerCase())
          ).map((row, indexRow) => (
            <TableRow
              key={row?.key || indexRow}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              width="100%"
              {...(row?.props ?? {})}
            >
              {Object.keys(row)
                .slice(0, columns?.length)
                .map((key, index) => (
                  <DataTableBodyCell
                    key={`${key}-${index}`}
                    {...(columns[index] ?? {})}
                    width="100%"
                  >
                    {row[key]}
                  </DataTableBodyCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DataTable;
