import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import CoverLayout from "layouts/authentication/components/CoverLayout";

import bgImage from "assets/images/bg-reset-cover.jpeg";

import service from "service";

function ResetPassword() {
  const navigate = useNavigate();
  const { token, userId } = useParams();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();

  const onSubmit = async () => {
    if (password === passwordConfirm) {
      await service.password.reset(userId, token, { password });
      navigate("/");
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Recadastrar senha
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Confirme abaixo a sua nova senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="password"
                error={!password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  return e;
                }}
                label="Senha"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  return e;
                }}
                error={password !== passwordConfirm}
                label="Confirmar senha"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                Redefinir senha
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
