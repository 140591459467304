import { createRef, useEffect, useState } from "react";
import { Icon, IconButton } from "@mui/material";

import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";

import colors from "assets/theme/base/colors";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

import service from "service";

function EditorAnswer({ canEdit, topicAnswerId, message }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const editorRef = createRef();
  const [editorAnswer, setEditorAnswer] = useState(EditorState.createEmpty());
  const [messageState, setMessage] = useState(EditorState.createEmpty());
  const [edit, setEdit] = useState();

  useEffect(() => {
    const contentBlock = htmlToDraft(message);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorAnswer(editorState);
    }
  }, [message]);

  const onEditorStateChange = (newEditorState) => {
    setEditorAnswer(newEditorState);
    const contenxtState = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setMessage(contenxtState);
  };

  const handleEdit = () => {
    setEdit(true);
    if (editorRef.current) {
      editorRef?.current?.focusEditor();
    }
  };

  const submit = () => {
    if (messageState !== message) {
      service.topics.updateAnswer(topicAnswerId, messageState);
    }
    setEdit(false);
  };

  return (
    <MDBox display="flex" flexDirection="column" width="100%" alignItems="end">
      <MDBox display="flex" flexDirection="column" alignItems="end" width="100%" mb={2}>
        {canEdit && (
          <IconButton onClick={handleEdit} size="small" color="secondary">
            <Icon>edit</Icon>
          </IconButton>
        )}
        <MDBox
          width="100%"
          sx={{
            color: colors.text.main,
          }}
        >
          <Editor
            ref={editorRef}
            editorState={editorAnswer}
            onEditorStateChange={onEditorStateChange}
            editorStyle={{
              height: "fit-content",
              width: "100%",
              ...(edit && {
                border: `1px solid ${darkMode ? "#FFFFFFAD" : "#49505736"}`,
                padding: "0px 8px",
                minHeight: 300,
              }),
            }}
            toolbarHidden={!edit}
            readOnly={!edit}
          />
        </MDBox>
      </MDBox>
      {edit && (
        <MDBox display="flex" gap={4}>
          <MDButton variant="outlined" color="error" onClick={() => setEdit(false)}>
            Cancelar
          </MDButton>
          <MDButton variant="outlined" color="success" onClick={submit}>
            Salvar
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default EditorAnswer;
