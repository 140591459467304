import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { GiChatBubble, GiCalendar } from "react-icons/gi";

import MDBox from "components/MDBox";

function Header({ children, tabValue, setTabValue }) {
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mt={-10} mb={5}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="sm"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            height="100%"
            mb={2}
          >
            <Tabs orientation="vertical" value={tabValue} onChange={handleSetTabValue}>
              <Tab
                sx={{ mt: 2, justifyContent: "flex-start", pl: 2 }}
                label="Tópicos"
                icon={<GiChatBubble size={20} />}
              />
              <Tab
                sx={{ mt: 2, justifyContent: "flex-start", pl: 2 }}
                label="Eventos"
                icon={<GiCalendar size={20} />}
              />
            </Tabs>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <MDBox display="flex" height="100%">
            {children}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
