import { useState } from "react";
import { Box } from "@mui/material";

import MDBox from "components/MDBox";
import LoadingBackdrop from "components/LoadingBackdrop";
import useAdmin from "hooks/useAdmin";

import Confirmables from "./components/Confirmables";
import UpConfirmations from "./components/UpConfirmations";

import Header from "./components/Header";

function Land() {
  const { loading } = useAdmin();
  const [tabValue, setTabValue] = useState(0);

  return (
    <MDBox pb={3}>
      <LoadingBackdrop open={loading} />
      <Header tabValue={tabValue} setTabValue={setTabValue}>
        <Box minHeight="50vh" width="100%">
          {tabValue === 0 && <Confirmables />}
          {tabValue === 1 && <UpConfirmations />}
        </Box>
      </Header>
    </MDBox>
  );
}

export default Land;
