import colors from "assets/theme-dark/base/colors";

const select = {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
      "& .Mui-selected": {
        backgroundColor: colors.white.main,
      },
    },

    icon: {
      color: "#FFF !important",
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },
  },
};

export default select;
