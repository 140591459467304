import api from "./api";

const list = async () => {
  const response = await api.get("/events");

  return response;
};

const get = async (eventId) => {
  const response = await api.get(`/events/${eventId}`);

  return response;
};

const create = async (data) => {
  const response = await api.post("/events", data);

  return response;
};

const edit = async (eventId, data) => {
  const response = await api.put(`/events/${eventId}`, data);

  return response;
};

const participate = async (eventId) => {
  const response = await api.get(`/events/${eventId}/participate`);

  return response;
};

const destroy = async (eventId) => {
  const response = await api.delete(`/events/${eventId}`);

  return response;
};

const events = { create, edit, list, get, participate, destroy };

export default events;
