import { Card, Grid } from "@mui/material";

import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import { useMaterialUIController } from "context";

function ActiveUsers({ columns, rows, columnsInactive, rowsInactive, reloadUsers }) {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor={sidenavColor}
            borderRadius="sm"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Aguardando ativação
            </MDTypography>
            <MDButton variant="outlined" onClick={reloadUsers}>
              Atualizar
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable table={{ columns: columnsInactive, rows: rowsInactive }} />
          </MDBox>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor={sidenavColor}
            borderRadius="sm"
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Membros
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              canSearch
              searchText="Busque por nome, telefone, e-mail ou personagem"
            />
          </MDBox>
        </Card>
      </Grid>
    </>
  );
}

export default ActiveUsers;
