import api from "./api";

const list = (params) => {
  return api.get("/users", params);
};

const me = () => {
  return api.get("/me");
};

const register = async (data) => {
  const response = await api.post("/register", data);

  return response;
};

const changeStatus = async (userId, data) => {
  const response = await api.put(`/users/change_status/${userId}`, data);

  return response;
};

const updateOne = async (userId, data) => {
  const response = await api.put(`/users/${userId}`, data);

  return response;
};

const updateSelf = async (data) => {
  const response = await api.put("self", data);

  return response;
};

const confirmMeta = async (userId) => {
  const response = await api.put(`/users/${userId}/confirm_meta`);

  return response;
};

const users = { list, me, register, changeStatus, confirmMeta, updateOne, updateSelf };

export default users;
