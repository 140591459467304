import { useEffect, useState } from "react";
import { filter, isString, orderBy } from "lodash";
import { useNavigate } from "react-router-dom";

import { Grid, Card, IconButton, Tooltip } from "@mui/material";

import { Check, Add as PlusIcon, AlarmOn as AlarmIcon } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useMaterialUIController } from "context";

import MDButton from "components/MDButton";
import useAuth from "hooks/useAuth";
import LoadingBackdrop from "components/LoadingBackdrop";

import { nameRoutes } from "routes";

import CardCollapse from "components/Cards/CardCollapse";

import service from "service";
import helpers from "helpers";

function Events() {
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const [controller] = useMaterialUIController();
  const navigate = useNavigate();
  const { loaded } = useAuth();

  const { sidenavColor } = controller;
  const { permissions } = useAuth();
  const [loading, setLoading] = useState();
  const [expanded, setExpanded] = useState(undefined);
  const [events, setEvents] = useState();

  const loadData = async () => {
    setLoading(true);
    const response = await service.events.list();

    setEvents(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (loaded) {
      loadData();
    }
  }, [loaded]);

  const handleNew = () => navigate(nameRoutes.events.new);

  const handleExpand = (index, expand) => {
    if (isString(index) && expand) {
      return setExpanded(index);
    }
    return setExpanded(undefined);
  };

  const handleParticipate = async (eventId) => {
    setLoading(true);
    await service.events.participate(eventId);

    loadData();
  };

  const onGoing = filter(events, { ended: false });
  const ended = filter(events, { ended: true });

  return (
    <DashboardLayout>
      <LoadingBackdrop open={loading} />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="lg"
            bgColor={sidenavColor}
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Próximos eventos
            </MDTypography>
            {permissions?.create_event && (
              <MDButton size="small" variant="outlined" onClick={handleNew}>
                Novo evento
              </MDButton>
            )}
          </MDBox>
          <MDBox p={4}>
            <Grid container spacing={4}>
              {onGoing.map((event) => (
                <Grid item xs={12} md={6} lg={4}>
                  <CardCollapse
                    // image={logo}
                    key={event?.id}
                    title={event?.name}
                    description={event?.description}
                    content={event?.content}
                    date={new Date(event?.startDate).toLocaleDateString("pt-br", dateOptions)}
                    expanded={expanded === event?.id}
                    setExpanded={(expand) => handleExpand(event?.id, expand)}
                    fullHeight={!onGoing?.map(({ id }) => id).includes(expanded)}
                    media={helpers.mountYoutubeMedia(event?.videoUrl)}
                    actions={
                      <>
                        {event?.participating ? (
                          <Tooltip title="Você está participando desse evento">
                            <IconButton aria-label="participating" color="success">
                              <Check />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Participar do evento">
                            <IconButton
                              aria-label="participate"
                              onClick={() => handleParticipate(event?.id)}
                              color="info"
                            >
                              <PlusIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <MDTypography
                          textAlign="center"
                          variant="caption"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {event?.participators} participante{event?.participators > 1 ? "s" : ""}
                        </MDTypography>
                        {event?.ongoing && (
                          <Tooltip title="O evento está em andamento.">
                            <IconButton aria-label="ongoing" color="warning">
                              <AlarmIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="lg"
            bgColor={sidenavColor}
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Eventos finalizados
            </MDTypography>
          </MDBox>
          <MDBox p={4}>
            <Grid container spacing={4}>
              {orderBy(ended, "endDate", "desc").map((event) => (
                <Grid item xs={12} md={6} lg={4}>
                  <CardCollapse
                    // image={logo}
                    key={event?.id}
                    title={event?.name}
                    description={event?.description}
                    content={event?.content}
                    date={new Date(event?.startDate).toLocaleDateString("pt-br", dateOptions)}
                    expanded={expanded === event?.id}
                    setExpanded={(expand) => handleExpand(event?.id, expand)}
                    fullHeight={!ended?.map(({ id }) => id).includes(expanded)}
                    media={helpers.mountYoutubeMedia(event?.videoUrl)}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Events;
