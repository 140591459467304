import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import personParadox from "assets/images/person_paradox.png";
import MenuButton from "components/MenuButton";
import MDButton from "components/MDButton";

export default function data(confirmables, confirmMeta, destroy) {
  function Author({ image, name, email }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function Job({ title, description }) {
    return (
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption">{description}</MDTypography>
      </MDBox>
    );
  }

  return {
    columns: [
      { Header: "Membro", accessor: "member", align: "left" },
      { Header: "Missão", accessor: "mission", width: "45%", align: "left" },
      { Header: "meta", accessor: "meta", align: "center" },
      { Header: "Ações", accessor: "action", align: "right" },
    ],

    rows: confirmables?.map((confirmable) => ({
      member: (
        <Author
          image={personParadox}
          name={confirmable?.user?.name}
          email={confirmable?.user?.profile?.name}
        />
      ),
      mission: (
        <Job
          title="Ataque / Defesa"
          description={`${confirmable?.attack?.character} - ${confirmable?.attack?.position} / ${confirmable?.attack?.character} - ${confirmable?.defense?.position}`}
        />
      ),

      meta: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={confirmable?.meta ? "OK" : "Pendente"}
            color={confirmable?.meta ? "success" : "info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <MenuButton>
          <MDButton
            onClick={() => destroy(confirmable)}
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Excluir
          </MDButton>
          {!confirmable?.meta && (
            <MDButton
              onClick={() => confirmMeta(confirmable)}
              variant="text"
              size="small"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Confirmar meta recebida
            </MDButton>
          )}
        </MenuButton>
      ),
      key: confirmable?.id,
    })),
  };
}
