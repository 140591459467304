import { Grid, InputLabel, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate } from "react-router-dom";

import Editor from "components/Editor";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import moment from "moment/moment";

import service from "service";

import schema from "./schema";

function EventForm({ event, setLoading, setError }) {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: event?.name || "",
      description: event?.description || "",
      startDate: event?.startDate ? moment(event?.startDate) : "",
      endDate: event?.endDate ? moment(event?.endDate) : "",
      videoUrl: event?.videoUrl || "",
      content: event?.content || "",
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (event?.id) {
        await service.events.edit(event?.id, {
          data,
        });
      } else {
        await service.events.create({
          data,
        });
      }
      setLoading(false);
      navigate("/eventos");
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <MDInput
                {...field}
                type="text"
                label="Título do evento"
                helperText={errors?.name?.message}
                error={!!errors?.name}
                fullWidth
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <DateTimePicker
                {...field}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    helperText={errors?.startDate?.message ?? props.helperText}
                    fullWidth
                  />
                )}
                label="Data de início"
                ampm={false}
                disablePast
              />
            )}
            name="startDate"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <DateTimePicker
                {...field}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    helperText={errors?.endDate?.message ?? props.helperText}
                    fullWidth
                  />
                )}
                label="Data de término"
                ampm={false}
                minDate={moment(watch("startDate")) ?? moment()}
                disablePast
              />
            )}
            name="endDate"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <MDInput
                {...field}
                fullWidth
                helperText={errors?.description?.message}
                error={!!errors?.description}
                label="Descrição do evento"
                multiline
                rows={2}
              />
            )}
            name="description"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <MDInput
                {...field}
                type="url"
                fullWidth
                helperText={errors?.videoUrl?.message}
                error={!!errors?.videoUrl}
                label="Link do vídeo de apresentação (Youtube)"
              />
            )}
            name="videoUrl"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sx={{ cursor: "text" }}>
          <MDBox mb={2}>
            <InputLabel error={!!errors?.content}>
              Conteúdo do evento. Utilize para anotar regras e outras observações
            </InputLabel>
          </MDBox>
          <Controller
            render={({ field }) => (
              <Editor onChange={field.onChange} defaultValue={event?.content || ""} />
            )}
            name="content"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <MDBox
            mt={2}
            gap={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <MDButton variant="outlined" color="secondary" onClick={() => navigate("/eventos")}>
              Voltar
            </MDButton>
            <MDButton variant="outlined" color="success" type="submit">
              Salvar
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </form>
  );
}

export default EventForm;
