import { useState } from "react";
import { Card, Grid } from "@mui/material";
import { Error } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDSnackbar from "components/MDSnackbar";

import DataTable from "components/Tables/DataTable";

import useAuth from "hooks/useAuth";
import { useMaterialUIController } from "context";

import service from "service";

import FormCharacter from "./components/FormCharacter";

function Job({ title, description }) {
  return (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
}

function Characters() {
  const [controller] = useMaterialUIController();
  const [openForm, setOpenForm] = useState(false);
  const [error, setError] = useState(false);

  const { sidenavColor } = controller;

  const { characters, loadCharacters } = useAuth();

  const destroy = async (character) => {
    await service.characters.destroy(character.id);
    loadCharacters();
  };

  const mountRows = () =>
    characters?.map((character) => ({
      name: <Job title={character?.name ?? "-"} description={character?.class?.name ?? "-"} />,
      class: <Job title={character?.class?.name ?? "-"} />,
      actions: (
        <MDTypography
          component="a"
          href="#"
          onClick={() => destroy(character)}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Excluir
        </MDTypography>
      ),
    }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                variant="gradient"
                alignItems="center"
                justifyContent="space-between"
                bgColor={sidenavColor ?? "info"}
                borderRadius="lg"
                coloredShadow={sidenavColor ?? "info"}
              >
                <MDTypography variant="h6" color="white">
                  Personagens
                </MDTypography>
                <MDButton size="small" variant="outlined" onClick={() => setOpenForm(true)}>
                  Novo personagem
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "Nome", accessor: "name", width: "45%", align: "left" },
                      { Header: "Classe", accessor: "class", align: "left" },
                      { Header: "Ações", accessor: "actions", align: "center" },
                    ],
                    rows: mountRows() ?? [],
                  }}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <FormCharacter open={openForm} setOpen={setOpenForm} setError={setError} />
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </DashboardLayout>
  );
}

export default Characters;
