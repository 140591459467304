import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MenuButton from "components/MenuButton";

import personParadox from "assets/images/person_paradox.png";
import { filter } from "lodash";

export default function data(users, viewUser, changeUserStatus) {
  function Author({ image, ...user }) {
    return (
      <MDBox
        onClick={() => viewUser(user)}
        sx={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDAvatar src={image} name={user?.name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {user?.name}
          </MDTypography>
          <MDTypography display="block" variant="caption">
            {user?.email}
          </MDTypography>
          <MDTypography variant="caption">{user?.phone}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  return {
    columns: [
      { Header: "Membro", accessor: "author", align: "left", width: "30%" },
      { Header: "Castle Siege", accessor: "csConfirmed", align: "center", width: "10%" },
      { Header: "status", accessor: "status", align: "center", width: "10%" },
      { Header: "Perfil", accessor: "profile", align: "left", width: "15%" },
      { Header: "Personagens", accessor: "characters", align: "left", width: "25%" },
      { Header: "Ações", accessor: "action", align: "right", width: "10%" },
    ],

    rows: users?.map((user) => ({
      author: <Author image={personParadox} {...user} />,
      csConfirmed: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={user?.csConfirmed ? "Confirmado" : "Não confirmado"}
            color={user?.csConfirmed ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={user?.status === 0 ? "Inativo" : user?.status === 2 ? "Banido" : "Ativo"}
            color={user?.status === 0 ? "error" : user?.status === 2 ? "primary" : "success"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      profile: <MDTypography variant="caption">{user?.profile?.name}</MDTypography>,
      characters: (
        <MDTypography variant="caption">
          {filter(user?.characters, { status: 0 })
            ?.map((char) => char.name)
            .join(" / ")}
        </MDTypography>
      ),
      action: (
        <MenuButton>
          <MDButton
            component={MDTypography}
            onClick={() => changeUserStatus(user, [0, 2].includes(user?.status) ? "1" : "0")}
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            {[0, 2].includes(user?.status) ? "Ativar" : "Inativar"}
          </MDButton>
          {user?.status !== 2 && (
            <MDButton
              component={MDTypography}
              onClick={() => changeUserStatus(user, "2")}
              variant="text"
              size="small"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Banir
            </MDButton>
          )}
          <MDButton
            onClick={() => viewUser(user)}
            component="a"
            href="#"
            variant="text"
            size="small"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Ver
          </MDButton>
        </MenuButton>
      ),
      key: user?.id,
      searchable: `${user?.name}${user?.characters?.map((char) => char.name).join(" / ")}${
        user?.phone
      }${user?.email}${user?.csConfirmed ? "confirmado" : "não confirmou"}${user?.profile?.name}`,
    })),
  };
}
