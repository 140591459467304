import { useState } from "react";
import { Box } from "@mui/material";

import MDBox from "components/MDBox";
import LoadingBackdrop from "components/LoadingBackdrop";
import useAdmin from "hooks/useAdmin";

import Topics from "./components/Topics";
import Events from "./components/Events";

import Header from "./components/Header";

function Forum() {
  const { loading } = useAdmin();
  const [tabValue, setTabValue] = useState(0);

  return (
    <MDBox pb={3}>
      <LoadingBackdrop open={loading} />
      <Header tabValue={tabValue} setTabValue={setTabValue}>
        <Box minHeight="50vh" width="100%">
          {tabValue === 0 && <Topics />}
          {tabValue === 1 && <Events />}
        </Box>
      </Header>
    </MDBox>
  );
}

export default Forum;
