import { useState } from "react";

import { Refresh, Error } from "@mui/icons-material";

import { Card, Grid } from "@mui/material";

import { filter, find, isEmpty } from "lodash";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PositionBox from "components/PositionBox";
import MDSnackbar from "components/MDSnackbar";

import { useMaterialUIController } from "context";
import useAdmin from "hooks/useAdmin";

import UserConfirmationDialog from "../UserConfirmationDialog";

function Confirmations() {
  const { confirmations, loadConfirmations } = useAdmin();
  const [userEdit, setUserEdit] = useState();
  const [error, setError] = useState(false);

  const setOpenCsEdit = (open) => {
    if (!open) {
      setUserEdit();
    }
  };

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const attackList = confirmations?.map(({ id, user, attack: { position, character } }) => ({
    id,
    user,
    position,
    character,
  }));
  const defenseList = confirmations?.map(({ id, user, defense: { position, character } }) => ({
    id,
    user,
    position,
    character,
  }));

  function mountPosition(list, position, color, textColor = "white") {
    const listFilters = filter(list, { position });

    if (isEmpty(listFilters)) {
      return <MDBox mt={4} />;
    }

    return listFilters.map((pos) => {
      const handleEdit = () => {
        const userPostion = find(confirmations, { user: { id: pos?.user?.id } });

        setUserEdit({
          userId: pos?.user?.id,
          pos: {
            attack: userPostion?.attack,
            defense: userPostion?.defense,
          },
        });
      };

      return (
        <PositionBox
          key={pos?.id}
          position={pos}
          color={color}
          textColor={textColor}
          handleEdit={handleEdit}
          editable
        />
      );
    });
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-2}
            py={2}
            px={2}
            variant="gradient"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="sm"
            bgColor={sidenavColor}
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Ataque
            </MDTypography>
            <MDButton
              startIcon={<Refresh />}
              size="small"
              variant="outlined"
              onClick={loadConfirmations}
            >
              Atualizar
            </MDButton>
          </MDBox>
          <MDBox px={4} pt={4} pb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} flexDirection="column">
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      PT Registro
                    </MDTypography>
                    {mountPosition(attackList, "PT de Registro", "success")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Ratio
                    </MDTypography>
                    {mountPosition(attackList, "Ratio", "error")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Desbuff
                    </MDTypography>
                    {mountPosition(attackList, "Desbuff", "primary")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Rebuff
                    </MDTypography>
                    {mountPosition(attackList, "Rebuff", "info")}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} flexDirection="column">
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Dark Lord
                    </MDTypography>
                    {mountPosition(attackList, "Dark Lord", "secondary")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Crown
                    </MDTypography>
                    {mountPosition(attackList, "Crown", "warning")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Auxílio
                    </MDTypography>
                    {mountPosition(attackList, "Auxílio", "light", "black")}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="sm"
            bgColor={sidenavColor}
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Defesa
            </MDTypography>
            <MDButton
              startIcon={<Refresh />}
              size="small"
              variant="outlined"
              onClick={loadConfirmations}
            >
              Atualizar
            </MDButton>
          </MDBox>
          <MDBox px={4} pt={4} pb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} flexDirection="column">
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Vice GM
                    </MDTypography>
                    {mountPosition(defenseList, "Vice GM", "success")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Ratio
                    </MDTypography>
                    {mountPosition(defenseList, "Ratio", "error")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Rebuff
                    </MDTypography>
                    {mountPosition(defenseList, "Rebuff", "info")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Desbuff
                    </MDTypography>
                    {mountPosition(defenseList, "Desbuff", "primary")}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} flexDirection="column">
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Dark Lord
                    </MDTypography>
                    {mountPosition(defenseList, "Dark Lord", "secondary")}
                  </Grid>
                  <Grid xs={12}>
                    <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                      Shield/Ice
                    </MDTypography>
                    {mountPosition(defenseList, "Shield/Ice", "warning")}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Grid>
      <UserConfirmationDialog
        open={!!userEdit}
        setOpen={setOpenCsEdit}
        setError={setError}
        userId={userEdit?.userId}
        pos={userEdit?.pos}
      />
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </Grid>
  );
}

export default Confirmations;
