import { useState } from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

import MDBox from "components/MDBox";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "components/Lists/ProfilesList";

import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

import useAuth from "hooks/useAuth";

import Edit from "./components/Edit";

function Overview() {
  const { user } = useAuth();
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Informações"
                info={{
                  telefone: user?.phone ?? "-",
                  email: user?.email ?? "-",
                  ataque: user?.mission_preference?.attack ?? "-",
                  defesa: user?.mission_preference?.defense ?? "-",
                }}
                social={[
                  {
                    link: user?.socials?.facebook ?? "https://www.facebook.com",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: user?.socials?.youtube ?? "https://youtube.com",
                    icon: <YouTubeIcon />,
                    color: "youtube",
                  },
                  {
                    link: user?.socials?.instagram ?? "https://www.instagram.com",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ action: () => setOpenEdit(true), tooltip: "Editar Perfil" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <ProfilesList title="Mensagens" profiles={[]} shadow={false} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
      {openEdit && <Edit open user={user} setOpen={setOpenEdit} />}
    </DashboardLayout>
  );
}

export default Overview;
