import { Close } from "@mui/icons-material";
import { Card, Dialog, Grid, IconButton } from "@mui/material";
import { useMaterialUIController } from "context";

import { isEmpty } from "lodash";

import useAuth from "hooks/useAuth";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PositionBox from "components/PositionBox";

function DataWithHeader({ headerTitle, data, color }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const entries = Object.entries(data);
  const data1 = entries.splice(0, (entries.length / 2).toFixed(0));
  const data2 = entries.splice(0, entries.length);

  return (
    <Card sx={{ mx: 2 }}>
      <MDBox
        px={2}
        mt={-3}
        mb={2}
        py={2}
        variant="gradient"
        borderRadius="sm"
        bgColor={sidenavColor}
        coloredShadow={sidenavColor}
      >
        <MDTypography variant="h6" color="white">
          {headerTitle}
        </MDTypography>
      </MDBox>
      <MDBox p={1}>
        <Grid container spacing={2}>
          {[data1, data2].map((col) => (
            <Grid item md={12} lg={isEmpty(data2) ? 12 : 6}>
              {col?.map(([positionName, rows]) => (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDTypography variant="h6" color="white">
                      {positionName}
                    </MDTypography>
                    {rows?.map(({ name, ...rest }) => (
                      <PositionBox
                        position={{ user: { name }, ...rest }}
                        showPosition
                        color={color}
                        mr={0}
                      />
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Card>
  );
}

function Position({ open = true, onClose }) {
  const { position } = useAuth();

  if (!position) {
    return null;
  }

  const { attack, defense } = position;

  return (
    <Dialog fullScreen open={open} onClose={onClose} maxWidth="md" fullWidth>
      <MDBox pt={2} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h5">Confira o posicionamento para o Castle Siege</MDTypography>
        <IconButton color="primary" onClick={onClose}>
          <Close />
        </IconButton>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <DataWithHeader headerTitle="Defesa" data={defense ?? {}} color="info" />
          </Grid>
          <Grid item xs={12}>
            <DataWithHeader headerTitle="Ataque" data={attack ?? {}} color="error" />
          </Grid>
        </Grid>
      </MDBox>
    </Dialog>
  );
}

export default Position;
