import { useEffect, useRef, useState } from "react";

import { IconButton } from "@mui/material";
import { Check, Edit } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MenuButton from "components/MenuButton";
import MDInput from "components/MDInput";

import constants from "helpers/constants";

import usePosition from "hooks/usePosition";

import AddCharacterDialog from "../AddCharacterDialog";

function CardPosition({ positionName }) {
  const { addCharacter, editPositionName, loadCharacters, isAttack } = usePosition();
  const [openAddCharacter, setOpenAddCharacter] = useState(false);
  const [editing, setEditing] = useState(false);
  const [positionNameState, setPositionName] = useState(positionName);
  const ref = useRef();

  const {
    DARK_LORD_ROLE,
    CROWN_ROLE,
    RATIO_ROLE,
    REBUFF_ROLE,
    DESBUFF_ROLE,
    ASSISTANT_ROLE,
    PT_ROLE,
    VICE_ROLE,
    SHIELD_ROLE,
  } = constants.characters;

  const loadCharactersWithKind = (role) => {
    loadCharacters(role, positionName);
  };

  useEffect(() => {
    if (!editing) {
      editPositionName(positionName, positionNameState);
    }
  }, [editing]);

  return (
    <MDBox
      width="100%"
      mt={2}
      mb={-1}
      px={2}
      pt={1}
      pb={2}
      variant="gradient"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="md"
      bgColor="error"
      coloredShadow="warning"
    >
      <MDBox width="80%" display="flex" alignItems="center" gap={2}>
        <MDInput
          type="text"
          variant="outlined"
          inputRef={ref}
          sx={{
            backgroundColor: "transparent !important",
          }}
          value={positionNameState}
          disabled={!editing}
          onChange={(e) => setPositionName(e?.target?.value)}
        />

        <IconButton
          size="small"
          onClick={() =>
            setEditing((edit) => {
              if (!edit) {
                ref.current?.focus();
              }
              return !edit;
            })
          }
        >
          {editing ? <Check color="white" /> : <Edit color="white" />}
        </IconButton>
      </MDBox>
      <MenuButton tooltipText="Carregar confirmações">
        <MDButton
          variant="text"
          color="secondary"
          fullWidth
          onClick={() => setOpenAddCharacter(true)}
        >
          Adicionar personagem
        </MDButton>
        <MDButton
          variant="text"
          color="secondary"
          fullWidth
          onClick={() => loadCharactersWithKind(DARK_LORD_ROLE)}
        >
          Carregar todos os DLs
        </MDButton>
        <MDButton
          variant="text"
          color="secondary"
          fullWidth
          onClick={() => loadCharactersWithKind(RATIO_ROLE)}
        >
          Carregar todos os Rátios
        </MDButton>
        <MDButton
          variant="text"
          color="secondary"
          fullWidth
          onClick={() => loadCharactersWithKind(DESBUFF_ROLE)}
        >
          Carregar todas Desbuff
        </MDButton>

        <MDButton
          variant="text"
          color="secondary"
          fullWidth
          onClick={() => loadCharactersWithKind(REBUFF_ROLE)}
        >
          Carregar todas Rebuff
        </MDButton>
        {isAttack ? (
          <>
            <MDButton
              variant="text"
              color="secondary"
              fullWidth
              onClick={() => loadCharactersWithKind(PT_ROLE)}
            >
              Carregar todos da PT de Registro
            </MDButton>
            <MDButton
              variant="text"
              color="secondary"
              fullWidth
              onClick={() => loadCharactersWithKind(CROWN_ROLE)}
            >
              Carregar todos os Crowns
            </MDButton>
            <MDButton
              variant="text"
              color="secondary"
              fullWidth
              onClick={() => loadCharactersWithKind(ASSISTANT_ROLE)}
            >
              Carregar todos os Auxílio
            </MDButton>
          </>
        ) : (
          <>
            <MDButton
              variant="text"
              color="secondary"
              fullWidth
              onClick={() => loadCharactersWithKind(SHIELD_ROLE)}
            >
              Carregar todos os Shield/Ice
            </MDButton>
            <MDButton
              variant="text"
              color="secondary"
              fullWidth
              onClick={() => loadCharactersWithKind(VICE_ROLE)}
            >
              Carregar todos os Vices
            </MDButton>
          </>
        )}
      </MenuButton>
      <AddCharacterDialog
        open={openAddCharacter}
        setOpen={setOpenAddCharacter}
        onSubmit={(character) => addCharacter(positionName, character)}
      />
    </MDBox>
  );
}

export default CardPosition;
