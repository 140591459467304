import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import breakpoints from "assets/theme/base/breakpoints";

import backgroundImage from "assets/images/bg-profile.jpeg";

function Header({ children, author, topic, isMobile }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="8.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <MDTypography variant="h3">{topic?.name}</MDTypography>
            <MDBox
              display="flex"
              gap={3}
              alignItems="center"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
            >
              <MDTypography variant="subtitle1">{topic?.description}</MDTypography>
              <MDTypography variant="caption">
                por {author?.name ?? "-"} em {new Date(topic?.createdAt).toLocaleString()}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

export default Header;
