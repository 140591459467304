import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // FormControlLabel,
  // Grid,
  MenuItem,
  // Switch,
} from "@mui/material";

import usePosition from "hooks/usePosition";

import { find, isEmpty } from "lodash";

import MDSelect from "components/MDSelect";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

function AddCharacterDialog({ open, setOpen, onSubmit }) {
  const { confirmations, kind } = usePosition();

  const onClose = () => {
    setOpen(false);
  };

  const [character, setCharacter] = useState();
  // const [nonExistant, setNonExistant] = useState(true);

  const submit = () => {
    if (!character) {
      return;
    }

    onSubmit(character);

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Adicionar personagem</DialogTitle>
      <DialogContent>
        {/* <FormControlLabel
          control={
            <Switch
              value={setNonExistant}
              onChange={(e) => {
                setNonExistant(e?.target?.checked);
              }}
            />
          }
          label="Criar confirmação"
        />
        {nonExistant ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDInput fullWidth label="Nome do membro" />
            </Grid>
            <Grid item xs={12}>
              <MDInput fullWidth label="Nome do personagem de ataque" />
            </Grid>
            <Grid item xs={12}>
              <MDInput fullWidth label="Missão no ataque" />
            </Grid>
            <Grid item xs={12}>
              <MDInput fullWidth label="Nome do personagem de defesa" />
            </Grid>
            <Grid item xs={12}>
              <MDInput fullWidth label="Missão na defesa" />
            </Grid>
          </Grid>
        ) : ( */}
        <MDSelect
          variant="standard"
          label="Personagem"
          error={isEmpty(character)}
          onChange={(e) => {
            setCharacter(find(confirmations, { id: e.target.value }));
          }}
          value={character?.id}
          fullWidth
        >
          {confirmations?.map((confirmation) => (
            <MenuItem key={confirmation?.id} value={confirmation?.id}>
              {`${confirmation?.[kind]?.character?.name} - ${confirmation?.[kind]?.position}`}
            </MenuItem>
          ))}
        </MDSelect>
        {/* )} */}
      </DialogContent>
      <DialogActions>
        <MDButton variant="outlined" color="error" onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton
          disabled={isEmpty(character)}
          variant="contained"
          color="success"
          onClick={submit}
        >
          Adicionar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddCharacterDialog;
