import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { isEmpty } from "lodash";

import useAdmin from "hooks/useAdmin";

import MDBox from "components/MDBox";
import LoadingBackdrop from "components/LoadingBackdrop";
import Confirmations from "./components/Confirmations";

import Header from "./components/Header";
import Position from "./components/Position";

function CastleSiege() {
  const { confirmations, loadConfirmations, loading } = useAdmin();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (isEmpty(confirmations)) {
      loadConfirmations();
    }
  }, []);

  return (
    <MDBox pb={3}>
      <LoadingBackdrop open={loading} />
      <Header tabValue={tabValue} setTabValue={setTabValue}>
        <Box minHeight="50vh" width="100%">
          {tabValue === 0 && <Confirmations />}
          {tabValue === 1 && <Position />}
        </Box>
      </Header>
    </MDBox>
  );
}

export default CastleSiege;
