import { ArrowDropDown, Error } from "@mui/icons-material";
import { FormControlLabel, Grid, MenuItem, Switch } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Editor from "components/Editor";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDSnackbar from "components/MDSnackbar";
import LoadingBackdrop from "components/LoadingBackdrop";

import constants from "helpers/constants";

import service from "service";

import { nameRoutes } from "routes";

import schema from "./schema";

function NewTopic() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      answer: "",
      category: "",
      kind: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const categoryObj = constants.topics.CATEGORIES.find(
        (obj) => obj.id?.toString() === data.category
      );
      await service.topics.create({
        data: {
          ...data,
          category: categoryObj,
          kind: data?.kind ? 1 : 0,
        },
      });
      setLoading(false);
      navigate(nameRoutes.forum);
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <DashboardLayout>
      <LoadingBackdrop open={loading} />
      <DashboardNavbar />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <MDSelect
                  {...field}
                  IconComponent={ArrowDropDown}
                  error={!!errors?.category}
                  placeholder="Categoria do tópico"
                  label="Categoria do tópico"
                  fullWidth
                >
                  {constants.topics.CATEGORIES.map((item) => (
                    <MenuItem key={item?.id} value={item.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </MDSelect>
              )}
              control={control}
              name="category"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <MDInput
                  {...field}
                  type="text"
                  label="Título do tópico"
                  helperText={errors?.name?.message}
                  error={!!errors?.name}
                  fullWidth
                />
              )}
              name="name"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <MDInput
                  {...field}
                  fullWidth
                  helperText={errors?.description?.message}
                  error={!!errors?.description}
                  label="Descrição do tópico"
                  multiline
                  rows={2}
                />
              )}
              name="description"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Switch />}
                  label="Membros podem responder esse tópico"
                />
              )}
              name="kind"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sx={{ cursor: "text" }}>
            <Controller
              render={({ field }) => <Editor onChange={field.onChange} />}
              name="answer"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox
              mt={2}
              gap={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width="100%"
            >
              <MDButton
                variant="outlined"
                color="secondary"
                onClick={() => navigate(nameRoutes.forum)}
              >
                Voltar
              </MDButton>
              <MDButton variant="outlined" color="success" type="submit">
                Salvar
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </form>
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </DashboardLayout>
  );
}

export default NewTopic;
