import { useState } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import { Error } from "@mui/icons-material";
import { GiSwordsPower } from "react-icons/gi";

import useAdmin from "hooks/useAdmin";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DialogConfirmation from "components/DialogConfirmation";
import MDSnackbar from "components/MDSnackbar";

import useAuth from "hooks/useAuth";

import service from "service";
import UserConfirmationDialog from "../UserConfirmationDialog";

function Header({ children, tabValue, setTabValue }) {
  const { setLoading, loadConfirmations, loadData, loadLastList } = useAdmin();
  const [openReset, setOpenReset] = useState(false);
  const [openCsConfirm, setOpenCsConfirm] = useState(false);
  const [error, setError] = useState(false);

  const { setUser } = useAuth();

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const resetConfirmations = async () => {
    try {
      setLoading(true);
      await service.castleSiege.resetConfirmations();
      await loadConfirmations();
      await loadData();
      await loadLastList();
      setUser((oldUser) => ({ ...oldUser, csConfirmed: false, confirmations: 0 }));
      setOpenReset(false);
    } catch (err) {
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
      setLoading(false);
    }
  };

  return (
    <MDBox position="relative" mt={-5} mb={5}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            height="100%"
            mb={2}
          >
            <Tabs
              orientation="vertical"
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ mt: -2.5 }}
            >
              <Tab
                sx={{ justifyContent: "flex-start", pl: 2 }}
                label="Confirmações"
                icon={<GiSwordsPower size={20} />}
              />
              <Tab
                sx={{ mt: 2, justifyContent: "flex-start", pl: 2 }}
                label="Posicionamento"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    radar
                  </Icon>
                }
              />
            </Tabs>
            <MDBox display="flex" flexDirection="column" gap={2} mb={3}>
              <MDButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setOpenCsConfirm(true)}
              >
                Adicionar confirmação
              </MDButton>
              <MDButton
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenReset(true)}
              >
                Resetar confirmações
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <MDBox display="flex" height="100%">
            {children}
          </MDBox>
        </Grid>
      </Grid>
      <DialogConfirmation
        open={openReset}
        setOpen={setOpenReset}
        onClose={() => setOpenReset(false)}
        title="Resetar confirmações"
        description="Tem certeza que deseja resetar as confirmações? Essa ação irá apagar todas as confirmações e exigirá que todos confirmem sua presença novamente!"
        colorCancel="success"
        colorConfirm="error"
        confirmAction={resetConfirmations}
      />
      <UserConfirmationDialog open={openCsConfirm} setOpen={setOpenCsConfirm} setError={setError} />
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
