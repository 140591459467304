import characters from "./characters";
import topics from "./topics";
import positions from "./positions";
import users from "./users";

const constants = {
  characters,
  topics,
  positions,
  users,
};

export default constants;
