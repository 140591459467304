import api from "./api";

const list = async () => {
  const response = await api.get("/topics");

  return response;
};

const get = async (topicId) => {
  const response = await api.get(`/topics/${topicId}`);

  return response;
};

const create = async (data) => {
  const response = await api.post("/topics", data);

  return response;
};

const answer = async (topicId, message) => {
  const response = await api.post(`/topics/${topicId}/answer`, { data: { message } });

  return response;
};

const destroy = async (topicId) => {
  const response = await api.delete(`/topics/${topicId}`);

  return response;
};

const updateAnswer = async (topicAnswerId, message) => {
  const response = await api.put(`/topics/answer/${topicAnswerId}`, { message });

  return response;
};

const topics = { create, list, get, destroy, updateAnswer, answer };

export default topics;
