import { useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { isEmpty } from "lodash";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function AddPositionDialog({ open, setOpen, onSubmit }) {
  const onClose = () => {
    setOpen(false);
  };

  const [positionName, setPositionName] = useState("");

  const submit = () => {
    if (!positionName) {
      return;
    }

    onSubmit(positionName);

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Adicionar posição</DialogTitle>
      <DialogContent>
        <MDInput
          type="text"
          label="Nome da posição"
          variant="standard"
          helperText={isEmpty(positionName) && "Preencha esse campo"}
          onChange={(e) => {
            setPositionName(e?.target?.value);
            return e;
          }}
          error={!positionName}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <MDButton variant="outlined" color="error" onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton
          disabled={isEmpty(positionName)}
          variant="contained"
          color="success"
          onClick={submit}
        >
          Adicionar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddPositionDialog;
