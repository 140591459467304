const DARK_LORD_BASE = "Dark Lord";

const DL_CLASS = DARK_LORD_BASE;
const BK_CLASS = "Blade Knight";
const SM_CLASS = "Soul Master";
const ME_CLASS = "Muse Elf";
const MG_CLASS = "Magic Gladiator";

const DARK_LORD_ROLE = DARK_LORD_BASE;
const CROWN_ROLE = "Crown";
const RATIO_ROLE = "Ratio";
const REBUFF_ROLE = "Rebuff";
const DESBUFF_ROLE = "Desbuff";
const ASSISTANT_ROLE = "Auxílio";
const PT_ROLE = "PT de Registro";
const VICE_ROLE = "Vice GM";
const SHIELD_ROLE = "Shield/Ice";

const COLOR_BY_CLASS = {
  [DL_CLASS]: "dark",
  [BK_CLASS]: "error",
  [SM_CLASS]: "info",
  [ME_CLASS]: "primary",
  [MG_CLASS]: "warning",
};

const CHARACTER_CLASSES = [
  {
    id: 1,
    name: DL_CLASS,
  },
  {
    id: 2,
    name: BK_CLASS,
  },
  {
    id: 3,
    name: SM_CLASS,
  },
  {
    id: 4,
    name: ME_CLASS,
  },
  {
    id: 5,
    name: MG_CLASS,
  },
];

const CHARACTER_ROLES_ATTACK = [
  {
    id: 1,
    name: DARK_LORD_ROLE,
  },
  {
    id: 2,
    name: CROWN_ROLE,
  },
  {
    id: 3,
    name: RATIO_ROLE,
  },
  {
    id: 4,
    name: REBUFF_ROLE,
  },
  {
    id: 5,
    name: DESBUFF_ROLE,
  },
  {
    id: 6,
    name: ASSISTANT_ROLE,
  },
  {
    id: 7,
    name: PT_ROLE,
  },
];

const CHARACTER_ROLES_DEFENSE = [
  {
    id: 1,
    name: DARK_LORD_ROLE,
  },
  {
    id: 2,
    name: RATIO_ROLE,
  },
  {
    id: 3,
    name: REBUFF_ROLE,
  },
  {
    id: 4,
    name: DESBUFF_ROLE,
  },
  {
    id: 5,
    name: VICE_ROLE,
  },
  {
    id: 6,
    name: SHIELD_ROLE,
  },
];

const characters = {
  DARK_LORD_ROLE,
  CROWN_ROLE,
  RATIO_ROLE,
  REBUFF_ROLE,
  DESBUFF_ROLE,
  ASSISTANT_ROLE,
  COLOR_BY_CLASS,
  PT_ROLE,
  VICE_ROLE,
  SHIELD_ROLE,
  CHARACTER_CLASSES,
  CHARACTER_ROLES_ATTACK,
  CHARACTER_ROLES_DEFENSE,
};

export default characters;
