import draftToHtml from "draftjs-to-html";

import { isEmpty } from "lodash";

import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import useAdmin from "hooks/useAdmin";
import { useMaterialUIController } from "context";

import colors from "assets/theme/base/colors";

function MyEditor({ onChange, defaultValue }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { users, loadData } = useAdmin();

  useEffect(() => {
    if (isEmpty(users)) {
      loadData();
    }
  }, []);

  const [editorState, setEditorState] = useState(
    defaultValue
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(defaultValue))
        )
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contenxtState = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    onChange(contenxtState);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      editorStyle={{
        minHeight: 300,
        color: colors.text.main,
        border: `1px solid ${darkMode ? "#FFFFFFAD" : "#49505736"}`,
        padding: "0px 8px",
      }}
      mention={{
        separator: " ",
        trigger: "@",
        suggestions: users.map((user) => ({
          text: user?.name,
          value: user?.name,
          url: `/profile/${user?.id}`,
        })),
      }}
    />
  );
}

export default MyEditor;
