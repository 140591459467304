import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Error } from "@mui/icons-material";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import LoadingBackdrop from "components/LoadingBackdrop";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import useAuth from "hooks/useAuth";

import service from "service";
import MDSnackbar from "components/MDSnackbar";
import EventForm from "../components/EventForm";

function ShowEvent() {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false);

  const { loaded } = useAuth();
  const params = useParams();

  const [event, setEvent] = useState();

  const loadData = async () => {
    const response = await service.events.get(params?.eventId);
    setEvent(response?.data?.event);
    setLoading(false);
  };

  useEffect(() => {
    if (loaded) {
      setLoading(true);

      loadData();
    }
  }, [loaded]);

  return (
    <DashboardLayout>
      <LoadingBackdrop open={loading} />
      <DashboardNavbar />
      {!!event?.id && <EventForm setLoading={setLoading} setError={setError} event={event} />}
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </DashboardLayout>
  );
}

export default ShowEvent;
