import { useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";

import MDButton from "components/MDButton";

import UpConfirmationForm from "./components/UpConfirmationForm";

function UpConfirmation({ setOpen, open, setError }) {
  const [loading, setLoading] = useState();

  const onClose = () => setOpen(false);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Informar UP e Boss</DialogTitle>
      <Divider />
      <DialogContent>
        {open && (
          <UpConfirmationForm setError={setError} setLoading={setLoading} onClose={onClose} />
        )}
      </DialogContent>
      <DialogActions>
        <MDButton disabled={loading} variant="outlined" color="error" onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton
          disabled={loading}
          variant="contained"
          color="success"
          type="submit"
          form="confirm-up"
        >
          Informar UP
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default UpConfirmation;
