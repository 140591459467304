import * as yup from "yup";

const schema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  content: yup.string(),
  videoUrl: yup.string().url(),
  startDate: yup.date().required().typeError("Preencha esse campo corretamente."),
  endDate: yup
    .date()
    .required()
    .min(yup.ref("startDate"), "Data de fim deve ser maior que a de início")
    .typeError("Preencha esse campo corretamente."),
});

export default schema;
