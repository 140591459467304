import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Card, Divider, MenuItem } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDSelect from "components/MDSelect";

import CoverLayout from "layouts/authentication/components/CoverLayout";
import { ArrowDropDown, Error } from "@mui/icons-material";
import { nameRoutes } from "routes";
import useAuth from "hooks/useAuth";

import service from "service";
import constants from "helpers/constants";

import schema from "./schema";

function CharacterStep() {
  const [error, setError] = useState(false);
  const [charactersCount, setCharactersCount] = useState(1);

  const { loadCharacters, characters } = useAuth();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await service.characters.create(data);
      await loadCharacters();
    } catch (err) {
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  const handleAdd = () => {
    setCharactersCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (!isEmpty(characters)) {
      navigate(nameRoutes.characters);
    }
  }, [characters]);

  const currentName = watch(`characters[${charactersCount - 1}].name`);
  const currentClass = watch(`characters[${charactersCount - 1}].class`);

  return (
    <CoverLayout xl={5} lg={8} md={10} image="https://wallpapercave.com/wp/wp2445551.jpg">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Registre seus personagens
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Preencha as informações dos seus personagens do jogo abaixo.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            {Array.from({ length: charactersCount }, (_, i) => i).map((charCount) => (
              <>
                <MDBox mb={2}>
                  <Controller
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        type="text"
                        label="Nome do personagem"
                        variant="standard"
                        onChange={(e) => {
                          e.target.value = e.target.value?.trim();
                          field.onChange(e);
                          return e;
                        }}
                        helperText={errors?.characters?.[charCount]?.name?.message}
                        error={!!errors?.characters?.[charCount]?.name}
                        fullWidth
                      />
                    )}
                    name={`characters[${charCount}].name`}
                    control={control}
                  />
                </MDBox>
                <MDBox mb={4}>
                  <Controller
                    render={({ field }) => (
                      <MDSelect
                        {...field}
                        variant="standard"
                        IconComponent={ArrowDropDown}
                        placeholder="Classe"
                        helperText={errors?.characters?.[charCount]?.class?.message}
                        error={!!errors?.characters?.[charCount]?.class}
                        fullWidth
                      >
                        {constants.characters.CHARACTER_CLASSES.map((item) => (
                          <MenuItem key={item?.id} value={item}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </MDSelect>
                    )}
                    name={`characters[${charCount}].class`}
                    control={control}
                  />
                </MDBox>
                <Divider />
              </>
            ))}
            <MDBox mt={4} mb={1} display="flex" gap={2}>
              <MDButton
                type="button"
                onClick={handleAdd}
                variant="gradient"
                color="primary"
                disabled={!currentName || currentName?.length < 3 || !currentClass}
                fullWidth
              >
                Adicionar mais
              </MDButton>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Salvar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </CoverLayout>
  );
}

export default CharacterStep;
