const CS_CATEGORY = 1;
const LAND_CATEGORY = 2;

const CLOSED_KIND = 0;
const DISCUSSION_KIND = 1;

const TOPIC_KINDS = [CLOSED_KIND, DISCUSSION_KIND];

const CATEGORIES = [
  {
    id: CS_CATEGORY,
    name: "Castle Siege",
  },
  {
    id: LAND_CATEGORY,
    name: "Land of Trials",
  },
];

const topics = {
  CLOSED_KIND,
  DISCUSSION_KIND,
  TOPIC_KINDS,
  CS_CATEGORY,
  LAND_CATEGORY,
  CATEGORIES,
};

export default topics;
