import axios from "axios";

const getUrl = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return "https://allyparadox.serv00.net";
  }

  if (process.env.REACT_APP_ENV === "test") {
    return "http://localhost:3001";
  }

  return "";
};

const api = axios.create({
  baseURL: getUrl(),
});

api.interceptors.request.use((config) => {
  config.headers.setAuthorization(
    `Bearer ${localStorage.getItem("authToken") || sessionStorage.getItem("authToken")}`
  );

  return config;
});

export default api;
