import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

import Menu from "@mui/material/Menu";

import MDBox from "components/MDBox";
import DefaultNavbarLink from "components/Navbars/DefaultNavbar/DefaultNavbarLink";

import { nameRoutes } from "routes";

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const location = useLocation();

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        {location.pathname === nameRoutes.home ? (
          <DefaultNavbarLink icon="account_circle" name="Registrar-se" route={nameRoutes.signUp} />
        ) : (
          <DefaultNavbarLink icon="key" name="Fazer login" route={nameRoutes.home} />
        )}
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
