import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import AuthContext from "contexts/AuthContext";
import PropTypes from "prop-types";

import service from "service";

import { useLocation, useNavigate } from "react-router-dom";
import { nameRoutes } from "routes";
import ScreenLoader from "components/ScreenLoader";

function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [characters, setCharacters] = useState();
  const [permissions, setPermissions] = useState();
  const [position, setPosition] = useState();
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const loadAllData = (userData, redirect = true) => {
    setLoading(true);
    setPermissions(userData.permissions);
    setCharacters(userData.characters);
    const userObject = { ...userData };
    delete userObject.characters;
    delete userObject.permissions;
    setUser(userObject);
    if (isEmpty(userData.characters)) {
      navigate(nameRoutes.charactersStep);
    } else {
      if (redirect) navigate(nameRoutes.home);
    }
    setTimeout(() => {
      setLoading(false);
      if (!loaded) {
        setLoaded(true);
      }
    }, 500);
  };

  const loadPosition = async () => {
    const response = await service.position.show();
    setPosition(response?.data);

    return response;
  };

  const loadData = async () =>
    service.users
      .me()
      .then((res) => {
        loadPosition().then(() => {
          loadAllData(res.data, false);
        });
      })
      .catch((err) => {
        throw err;
      });

  useEffect(() => {
    const load = async () => {
      try {
        setLoaded(false);
        await loadData();
        setLoaded(true);
      } catch {
        service.authentication.logOut();
        navigate(nameRoutes.home);
      }
    };

    if (service.authentication.loggedIn() && !user) {
      load();
    }
  }, []);

  useEffect(() => {
    if (
      service.authentication.loggedIn() &&
      !!user &&
      isEmpty(characters) &&
      location.pathname !== nameRoutes.charactersStep
    ) {
      navigate(nameRoutes.charactersStep);
    }
  }, [location.pathname]);

  const loadCharacters = async () => {
    setLoading(true);
    try {
      const response = await service.characters.list();
      setCharacters(response.data);
      setLoading(false);
    } catch (err) {
      setTimeout(() => setLoading(false), 500);
      throw err;
    }
  };

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          user,
          characters,
          permissions,
          position,
          loadPosition,
          setUser,
          loadData,
          loaded,
          setLoaded,
          loadCharacters,
          loadAllData,
        }),
        [loaded, loading, user]
      )}
    >
      <ScreenLoader
        loaded={
          !service.authentication.loggedIn() ||
          (service.authentication.loggedIn() && user?.name && loaded)
        }
      />
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
