import api from "./api";

const reset = async (userId, token, data) => {
  const response = await api.post(`/password/${userId}/${token}`, data);
  return response;
};

const authentication = { reset };

export default authentication;
