import { useEffect, useState } from "react";

import { Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import LoadingBackdrop from "components/LoadingBackdrop";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Editor from "components/Editor";

import personParadox from "assets/images/person_paradox.png";

import useAuth from "hooks/useAuth";

import service from "service";

import constants from "helpers/constants";

import TopicAnswer from "./components/TopicAnswer";
import Header from "./components/Header";

function ShowTopic() {
  const muiTheme = useTheme();

  const [loading, setLoading] = useState();
  const { loaded, permissions } = useAuth();
  const params = useParams();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const [topic, setTopic] = useState();
  const [answers, setAnswers] = useState();
  const [newAnswer, setNewAnswer] = useState("");

  const loadData = async () => {
    const response = await service.topics.get(params?.topicId);
    setTopic(response?.data?.topic);
    setAnswers(response?.data?.answers);
    setLoading(false);
  };

  useEffect(() => {
    if (loaded) {
      setLoading(true);

      loadData();
    }
  }, [loaded]);

  const postAnswer = async () => {
    try {
      setLoading(true);
      await service.topics.answer(topic?.id, newAnswer);
      await loadData();
    } catch {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <LoadingBackdrop open={loading} />
      <DashboardNavbar />
      <Header author={topic?.user} topic={topic} isMobile={isMobile}>
        <MDBox mt={2} mb={3}>
          <Grid container spacing={1}>
            {answers?.map((answer) => {
              const canEdit =
                permissions?.edit_answer ||
                (topic.kind === constants.topics.DISCUSSION_KIND && answer.owner);

              return (
                <Grid item xs={12}>
                  <Divider />
                  <MDBox display="flex " flexDirection={isMobile ? "column" : "row"} gap={5}>
                    <MDBox
                      minWidth="150px"
                      height="100%"
                      mt={0.5}
                      lineHeight={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <MDAvatar src={personParadox} alt="profile-image" size="xl" shadow="sm" />
                      <MDTypography variant="h5" fontWeight="medium">
                        {answer?.user?.name ?? "-"}
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {answer?.user?.profile?.name ?? "-"}
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {new Date(answer?.createdAt).toLocaleString()}
                      </MDTypography>
                    </MDBox>
                    <TopicAnswer
                      canEdit={canEdit}
                      topicAnswerId={answer?.id}
                      message={answer?.message}
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
      </Header>
      {!loading && topic?.kind !== constants.topics.CLOSED_KIND && (
        <MDBox mx={3}>
          <MDBox sx={{ cursor: "text" }}>
            <Editor onChange={setNewAnswer} />
          </MDBox>
          <MDBox gap={2} mt={2} mb={10} display="flex" justifyContent="end">
            <MDButton variant="outlined">Voltar</MDButton>
            <MDButton color="success" variant="outlined" onClick={postAnswer}>
              Enviar
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default ShowTopic;
