import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";

import useAuth from "hooks/useAuth";
import service from "service";

import schema from "./schema";

function UpConfirmationForm({ setError, setLoading, onClose }) {
  const { characters, setUser, user } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      up: user?.upConfirmed?.up ?? "",
      boss: user?.upConfirmed?.boss ?? "",
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await service.upConfirmation.confirm({ data });
      setUser((oldUser) => ({
        ...oldUser,
        upConfirmed: true,
      }));
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="confirm-up">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography variant="h6">UP</MDTypography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <MDSelect
                {...field}
                variant="standard"
                label="Personagem"
                error={!!errors?.up}
                fullWidth
              >
                {characters?.map((character) => (
                  <MenuItem key={character?.id} value={character?.id}>
                    {`${character.name} - ${character.class?.name}`}
                  </MenuItem>
                ))}
              </MDSelect>
            )}
            name="up"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <MDTypography variant="h6">Boss</MDTypography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <MDSelect
                {...field}
                variant="standard"
                label="Personagem"
                error={!!errors?.boss}
                fullWidth
              >
                {characters?.map((character) => (
                  <MenuItem key={character?.id} value={character?.id}>
                    {`${character.name} - ${character.class?.name}`}
                  </MenuItem>
                ))}
              </MDSelect>
            )}
            name="boss"
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default UpConfirmationForm;
