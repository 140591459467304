import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import useAuth from "hooks/useAuth";
import constants from "helpers/constants";
import service from "service";
import schema from "./schema";

function FormCharacter({ open, setOpen, setError }) {
  const { loadCharacters } = useAuth();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const onClose = () => setOpen(false);

  const onSubmit = async (data) => {
    try {
      await service.characters.create({ characters: [data] });
      await loadCharacters();
      reset();
      onClose();
    } catch (err) {
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Novo personagem</DialogTitle>
        <DialogContent>
          <MDBox mb={2}>
            <Controller
              render={({ field }) => (
                <MDInput
                  {...field}
                  type="text"
                  label="Nome do personagem"
                  variant="standard"
                  onChange={(e) => {
                    e.target.value = e.target.value?.trim();
                    field.onChange(e);
                    return e;
                  }}
                  helperText={errors?.name?.message}
                  error={!!errors?.name}
                  fullWidth
                />
              )}
              name="name"
              control={control}
            />
          </MDBox>
          <MDBox>
            <Controller
              render={({ field }) => (
                <MDSelect
                  {...field}
                  variant="standard"
                  label="Classe"
                  error={!!errors?.class}
                  fullWidth
                >
                  {constants.characters.CHARACTER_CLASSES.map((item) => (
                    <MenuItem key={item?.id} value={item}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </MDSelect>
              )}
              name="class"
              control={control}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" color="error" onClick={onClose}>
            Cancelar
          </MDButton>
          <MDButton variant="contained" color="success" type="submit">
            Salvar
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FormCharacter;
