import { useEffect } from "react";

import { isEmpty } from "lodash";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "components/Tables/DataTable";
import useAdmin from "hooks/useAdmin";

import { useMaterialUIController } from "context";

import service from "service";

import confirmablesData from "./data/confirmablesData";

function Tables() {
  const { confirmables, loadLastList, setLoading, loadUpConfirmations } = useAdmin();
  const confirmMeta = async (confirmable) => {
    try {
      setLoading(true);
      await service.castleSiege.confirmMeta(
        confirmables?.id,
        confirmable?.id || confirmable?.user?.name
      );
      await loadUpConfirmations();
      await loadLastList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const destroy = async (confirmable) => {
    try {
      setLoading(true);
      await service.castleSiege.destroyHistory(confirmables?.id, confirmable?.id);
      await loadLastList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { columns, rows } = confirmablesData(confirmables?.data ?? [], confirmMeta, destroy);
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    if (isEmpty(confirmables)) {
      loadLastList();
    }
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="sm"
              bgColor={sidenavColor}
              coloredShadow={sidenavColor}
            >
              <MDTypography variant="h6" color="white">
                Lista de liberados do último CS
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable table={{ columns, rows }} noEndBorder />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Tables;
