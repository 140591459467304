import React from "react";

import logo from "assets/images/logo_bg_1.png";
import { Box, styled } from "@mui/material";

const Root = styled(Box)(({ ownerState }) => {
  const { loaded } = ownerState;

  if (loaded)
    return {
      transition: "all 500ms ease",
      position: "relative",
      opacity: 0,
      visibility: "hidden",
      zIndex: -1,
    };

  return {
    overflow: "hidden",
    transition: "all 500ms ease",
    transitionDelay: 1500,
    position: "relative",
    zIndex: 9999,
  };
});

const Bg = styled(Box)(() => ({
  backgroundImage: "linear-gradient(0deg, rgba(50, 58, 84), rgba(26, 32, 53))",
  position: "fixed",
  height: "100%",
  width: "100%",
  transform: "translate(0,0)",
  transition: "all 500ms ease",
  transitionDelay: 1200,
}));

const Logo = styled("figure")(({ theme }) => ({
  width: "200px",
  height: "200px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transition: "all 1s ease",
  animation: "blink-animation 2s steps(5, start) infinite",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.down("sm")]: {
    width: "30vw",
    height: "30vh",
  },
}));

function ScreenLoader({ loaded, children }) {
  return (
    <>
      <Root ownerState={{ loaded }}>
        <Bg />
        <Logo>
          <img alt="Logo" src={logo} width="100%" />
        </Logo>
      </Root>
      {loaded && children}
    </>
  );
}

export default ScreenLoader;
