import * as yup from "yup";

const schema = yup.object({
  category: yup.string().required(),
  name: yup.string().required(),
  description: yup.string(),
  kind: yup.boolean().default(false),
  answer: yup.string().required(),
});

export default schema;
