import { useState } from "react";

import { Grid } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import useAdmin from "hooks/useAdmin";

import MDBox from "components/MDBox";
import LoadingBackdrop from "components/LoadingBackdrop";

import { GiSplitBody, GiSwordman } from "react-icons/gi";

import service from "service";

import usersData from "./data/usersData";
import ActiveUsers from "./components/ActiveUsers";
import InactiveUsers from "./components/InactiveUsers";

import ViewUser from "../ViewUser";

function Users() {
  const [tabValue, setTabValue] = useState(0);

  const { users, usersInactive, usersBanned, loadData, loading: loadingAuth } = useAdmin();
  const [loading, setLoading] = useState();

  const [user, setUser] = useState();
  const [openView, setOpenView] = useState(false);

  const viewUser = (currentUser) => {
    setUser(currentUser);
    setOpenView(true);
  };

  const changeUserStatus = async (currentUser, status) => {
    try {
      setLoading(true);
      await service.users.changeStatus(currentUser?.id, { user: { status } });
      await loadData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const reloadUsers = async () => {
    setLoading(true);
    await loadData();
    setLoading(false);
  };

  const { columns, rows } = usersData(users ?? [], viewUser, changeUserStatus);
  const { columns: columnsInactive, rows: rowsInactive } = usersData(
    usersInactive ?? [],
    viewUser,
    changeUserStatus
  );
  const { columns: columnsBanned, rows: rowsBanned } = usersData(
    usersBanned ?? [],
    viewUser,
    changeUserStatus
  );

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <>
      <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ mt: 2 }}>
        <Tab label="Ativos" icon={<GiSwordman size={20} />} />
        <Tab label="Banidos" icon={<GiSplitBody size={20} />} />
      </Tabs>
      <MDBox pt={6} pb={3}>
        <LoadingBackdrop open={loading || loadingAuth} />
        <Grid container spacing={6}>
          {tabValue === 0 && (
            <ActiveUsers
              columns={columns}
              rows={rows}
              columnsInactive={columnsInactive}
              rowsInactive={rowsInactive}
              reloadUsers={reloadUsers}
            />
          )}
          {tabValue === 1 && (
            <InactiveUsers
              columns={columnsBanned}
              rows={rowsBanned}
              columnsInactive={columnsInactive}
              rowsInactive={rowsInactive}
              reloadUsers={reloadUsers}
            />
          )}
        </Grid>
        {openView && <ViewUser open setOpen={setOpenView} setError={() => {}} user={user} />}
      </MDBox>
    </>
  );
}

export default Users;
