import { useState, useEffect } from "react";

import { useLocation, Link } from "react-router-dom";

import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import Breadcrumbs from "components/Breadcrumbs";

import ts3 from "assets/images/ts3server.png";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import useAuth from "hooks/useAuth";

import routes, { nameRoutes } from "routes";
import { Tooltip } from "@mui/material";

function DashboardNavbar({ absolute, light, isMini }) {
  const location = useLocation();
  const [transparentNavbar, setTransparentNavbar] = useState(false);

  const { user, characters } = useAuth();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, fixedNavbar, openConfigurator, darkMode, sidenavColor } = controller;
  const route = location.pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    setTransparentNavbar(false);

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleTeamSpeak = () =>
    window.open(`ts3server://allyparadox?nickname=${user?.name} - ${characters?.[0]?.name}`);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (!light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const translateRoute = () => {
    return routes.find((router) => router?.route === location.pathname)?.name || "Início";
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={translateRoute()} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <Link to={nameRoutes.profile}>
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <Tooltip title="Acessar TeamSpeak">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleTeamSpeak}
                  disabled={!user?.name}
                >
                  <Box
                    mt={-4}
                    mr={-5}
                    width="100px"
                    height="100px"
                    sx={(theme) => ({
                      backgroundImage: `linear-gradient(190deg, rgba(50, 58, 84, 0.4), rgba(26, 32, 53, 0.4)), url(${ts3})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      borderRadius: "50%",
                      boxShadow: theme?.boxShadows?.colored?.[sidenavColor],
                    })}
                  />
                </IconButton>
              </Tooltip>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
