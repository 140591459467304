import * as yup from "yup";

const schema = yup.object({
  attack: yup.object().shape({
    character: yup.string().required(),
    position: yup.string().required(),
  }),
  defense: yup.object().shape({
    character: yup.string().required(),
    position: yup.string().required(),
  }),
});

export default schema;
