import { Fragment, useEffect, useMemo } from "react";

import { isEmpty } from "lodash";

import {
  ChevronLeft,
  ChevronRight,
  Save,
  SaveAs,
  SendRounded,
  WarningSharp,
} from "@mui/icons-material";
import { Card, IconButton } from "@mui/material";

import { useMaterialUIController } from "context";

import useAdmin from "hooks/useAdmin";
import usePosition from "hooks/usePosition";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingBackdrop from "components/LoadingBackdrop";

import AddItem from "./components/AddItem";
import CardCharacter from "./components/CardCharacter";
import CardPosition from "./components/CardPosition";
import AddPositionDialog from "./components/AddPositionDialog";

function Position() {
  const {
    addPosition,
    currentPosition,
    changed,
    draft,
    loading,
    isAttack,
    openAddPosition,
    attackState,
    defenseState,
    setCurrentPosition,
    setOpenAddPosition,
    createDraft,
    publish,
    update,
    reset,
  } = usePosition();
  const positionPublished = draft?.status === 1;

  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;

  const { confirmations, loadConfirmations } = useAdmin();

  useEffect(() => {
    if (isEmpty(confirmations)) {
      loadConfirmations();
    }
  }, []);

  const handleAddPosition = () => {
    setOpenAddPosition(true);
  };

  const setPos = () => setCurrentPosition((pos) => (pos === 0 ? 1 : 0));
  const positionState = useMemo(
    () => (isAttack ? attackState : defenseState),
    [currentPosition, attackState, defenseState]
  );

  return (
    <Card>
      <LoadingBackdrop open={loading} />
      <MDBox
        mx={2}
        mt={-3}
        py={2}
        px={2}
        variant="gradient"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="sm"
        bgColor={sidenavColor}
        coloredShadow={sidenavColor}
      >
        <MDBox display="flex" alignItems="center" gap={2}>
          <IconButton onClick={setPos} size="small">
            <ChevronLeft color="white" />
          </IconButton>
          <MDTypography variant="h6" color="white">
            {currentPosition === 0 ? "Defesa" : "Ataque"}
          </MDTypography>
          <IconButton onClick={setPos} size="small">
            <ChevronRight color="white" />
          </IconButton>
        </MDBox>
        <MDBox display="flex" alignItems="center" gap={2}>
          {!positionPublished ? (
            <MDButton
              startIcon={<SaveAs />}
              size="small"
              variant="outlined"
              onClick={draft?.id ? update : createDraft}
              disabled={!changed}
            >
              {draft?.id ? "Salvar mudanças" : "Salvar rascunho"}
            </MDButton>
          ) : (
            <MDButton startIcon={<WarningSharp />} size="small" variant="outlined" onClick={reset}>
              Resetar posicionamento
            </MDButton>
          )}
          <MDButton
            startIcon={positionPublished ? <Save /> : <SendRounded />}
            size="small"
            variant="outlined"
            onClick={positionPublished ? update : publish}
            disabled={!draft?.id || (positionPublished && !changed)}
          >
            {positionPublished ? "Salvar mudanças" : "Publicar"}
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox position="relative" sx={{ minHeight: "calc(40vh + 250px)" }} width="100%">
        <MDBox key={currentPosition}>
          {Object.entries(positionState).map((entry) => (
            <Fragment key={entry?.name}>
              <CardPosition positionName={entry?.[0]} kind="attack" />
              {entry?.[1]?.map((data) => (
                <CardCharacter
                  positionName={entry?.[0]}
                  key={data?.id}
                  data={data}
                  color={isAttack ? "warning" : "info"}
                />
              ))}
            </Fragment>
          ))}
        </MDBox>
        <AddItem handleAddPosition={handleAddPosition} />
      </MDBox>
      <AddPositionDialog
        open={openAddPosition}
        setOpen={setOpenAddPosition}
        onSubmit={addPosition}
      />
    </Card>
  );
}

export default Position;
