import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DefaultNavbar from "components/Navbars/DefaultNavbar";
import PageLayout from "components/LayoutContainers/PageLayout";

import Footer from "layouts/authentication/components/Footer";

function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      <DefaultNavbar />
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
      >
        <MDBox mt={20} px={1} width="100%" mx="auto">
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={9} md={6} lg={5} xl={4}>
              {children}
            </Grid>
          </Grid>
        </MDBox>
        <Footer light />
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
