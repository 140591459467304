import { useEffect, useMemo, useState } from "react";
import { isEmpty, isNil } from "lodash";
import AdminContext from "contexts/AdminContext";
import PropTypes from "prop-types";

import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";

import service from "service";

import { nameRoutes } from "routes";
import constants from "helpers/constants";

function AuthProvider({ children }) {
  const auth = useAuth();
  const { user, permissions, loaded: authLoaded } = auth;
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [usersBanned, setUsersBanned] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [topics, setTopics] = useState([]);
  const [events, setEvents] = useState([]);
  const [confirmables, setConfirmables] = useState([]);
  const [upConfirmations, setUpConfirmables] = useState([]);

  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const loadAllData = (usersData) => {
    setUsers(usersData?.filter((u) => u.status === 1 || isNil(u?.status)));
    setUsersInactive(usersData?.filter((u) => u.status === 0));
    setUsersBanned(usersData?.filter((u) => u.status === 2));
    setTimeout(() => setLoading(false), 500);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await service.users.list({});
      loadAllData(response.data);
    } catch {
      setLoading(false);
    }
  };

  const loadConfirmations = async () => {
    try {
      setLoading(true);
      const response = await service.castleSiege.listConfirmations();
      setConfirmations(response.data);
    } finally {
      setLoading(false);
    }
  };

  const loadTopics = async () => {
    try {
      setLoading(true);
      const response = await service.topics.list();
      setTopics(response.data);
    } finally {
      setLoading(false);
    }
  };

  const loadEvents = async () => {
    try {
      setLoading(true);
      const response = await service.events.list();
      setEvents(response.data);
    } finally {
      setLoading(false);
    }
  };

  const loadUpConfirmations = async () => {
    try {
      setLoading(true);
      const response = await service.upConfirmation.listConfirmations();
      setUpConfirmables(response.data);
    } finally {
      setLoading(false);
    }
  };

  const loadLastList = async () => {
    try {
      setLoading(true);
      const response = await service.castleSiege.lastCsList();
      setConfirmables(response.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      setLoaded(false);
      await loadData();
      setLoading(false);
      setLoaded(true);
    };

    if (authLoaded && service.authentication.loggedIn()) {
      if (
        user?.profile?.role === constants.users.MEMBER_ROLE &&
        location.pathname === nameRoutes.panel
      ) {
        navigate(nameRoutes.home);
      } else {
        if (permissions && isEmpty(users) && location.pathname === nameRoutes.panel) {
          if (permissions.admin_panel) {
            load();
          }
        }
      }
    }
  }, [location.pathname, authLoaded, permissions, user]);

  return (
    <AdminContext.Provider
      value={useMemo(
        () => ({
          users,
          usersInactive,
          usersBanned,
          loaded,
          loadData,
          confirmations,
          loadConfirmations,
          upConfirmations,
          loadUpConfirmations,
          confirmables,
          loadLastList,
          topics,
          loadTopics,
          events,
          loadEvents,
          loading,
          setLoading,
        }),
        [loaded, loading]
      )}
    >
      {children}
    </AdminContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
