import PropTypes from "prop-types";

import { Box } from "@mui/material";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

function CardTopic({ title, description, date, imgSrc, active, ...rest }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  return (
    <Card {...rest} sx={{ height: "100%", cursor: "pointer" }}>
      <MDBox padding="1rem">
        <MDBox
          borderRadius="5px"
          mt={-5}
          pt={1}
          pb={1}
          px={1}
          height="13.5rem"
          bgColor={sidenavColor}
        >
          <Box
            sx={{
              backgroundImage: `linear-gradient(195deg, rgba(50, 58, 84, 0.4), rgba(26, 32, 53, 0.4)), url(${imgSrc})`,
              backgroundPosition: "center",
              backgroundPositionY: "30%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="relative"
            zIndex={1}
          />
        </MDBox>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6">{title}</MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>{active ? "check" : "schedule"}</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

CardTopic.defaultProps = {
  description: "",
};

CardTopic.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default CardTopic;
