import { Card } from "@mui/material";
import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";

import useAdmin from "hooks/useAdmin";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";

import LoadingBackdrop from "components/LoadingBackdrop";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";

import usersData from "./data/usersData";

function Users() {
  const { users, loadData, loading } = useAdmin();

  const { columns, rows } = useMemo(() => usersData(users ?? []), [users?.length]);

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    if (isEmpty(users)) {
      loadData();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <LoadingBackdrop open={loading} />
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            borderRadius="lg"
            variant="gradient"
            bgColor={sidenavColor}
            coloredShadow={sidenavColor}
          >
            <MDTypography variant="h6" color="white">
              Membros
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable table={{ columns, rows }} />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
