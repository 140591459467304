import * as yup from "yup";

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  missionPreference: yup.object().shape({
    attack: yup.string(),
    defense: yup.string(),
  }),
  socials: yup.object().shape({
    facebook: yup.string().url(),
    youtube: yup.string().url(),
    instagram: yup.string().url(),
  }),
});

export default schema;
