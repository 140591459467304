import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import constants from "helpers/constants";

function Userbar({ character }) {
  return (
    <MDBox
      width="100%"
      display="flex"
      justifyContent="space-between"
      variant="gradient"
      color="primary"
      px={2}
      py={0.5}
      bgColor={constants.characters.COLOR_BY_CLASS[character?.class?.name]}
      sx={{ boxSizing: "border-box" }}
    >
      <MDBox width="50%">
        <MDTypography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {character?.name}
        </MDTypography>
      </MDBox>
      <MDBox width="50%">
        <MDTypography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {character?.class?.name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Userbar;
