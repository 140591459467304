import colors from "assets/theme-dark/base/colors";

const { text } = colors;

const helperText = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};

export default helperText;
