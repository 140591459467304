import { createRef } from "react";

import { Collapse, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import HTMLRenderer from "react-html-renderer";

import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import EventIcon from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowRight";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// import logo from "assets/images/logo_bg_1.png";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

function CardCollapse({
  title,
  date,
  description,
  content,
  actions,
  action,
  expanded,
  setExpanded,
  media,
}) {
  const muiTheme = useTheme();

  const actionRef = createRef(null);
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("md"));

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (expanded) {
      actionRef?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  return (
    <Card
      ref={actionRef}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.sidenav,
        height: "100%",
        justifyContent: "space-between",
      })}
    >
      <MDBox width="100%">
        <CardHeader
          color="white"
          avatar={
            <EventIcon
              sx={(theme) => ({ color: theme.palette.text.main })}
              aria-label="recipe"
              fontSize="large"
            />
          }
          action={action}
          sx={{
            "& .MuiCardHeader-content": {
              width: "100%",
            },
          }}
          title={
            <MDBox width="calc(100% - 20px)">
              <Tooltip title={title}>
                <MDTypography
                  variant="subtitle2"
                  {...(!expanded && {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    sx: {
                      width: "inherit",
                    },
                  })}
                >
                  {title}
                </MDTypography>
              </Tooltip>
            </MDBox>
          }
          subheader={<MDTypography variant="body2">{date}</MDTypography>}
        />
        {expanded ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent {...(!isMobile && { style: { overflow: "auto", maxHeight: "350px" } })}>
              <MDTypography variant="body2" sx={{ marginBottom: 2 }}>
                <HTMLRenderer html={content} />
              </MDTypography>
            </CardContent>
          </Collapse>
        ) : (
          <>
            {!!media && (
              <MDBox width="calc(100% - 15px)">
                <CardMedia
                  sx={{
                    width: "inherit !important",
                    minHeight: "200px",
                  }}
                  component={media.video ? "iframe" : "img"}
                  {...(media?.video ? { src: media?.url } : { image: media?.url })}
                  alt="media-event"
                />
              </MDBox>
            )}
            <CardContent>
              <MDTypography variant="body2">{description}</MDTypography>
            </CardContent>
          </>
        )}
      </MDBox>
      <CardActions disableSpacing>
        {actions}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <Tooltip title={expanded ? "Fechar detalhes" : "Ver mais detalhes"}>
            <ExpandMoreIcon color="secondary" />
          </Tooltip>
        </ExpandMore>
      </CardActions>
    </Card>
  );
}
export default CardCollapse;
