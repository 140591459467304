import { useContext, useMemo } from "react";
import { filter, reject, map, uniqBy, isArray, findIndex, find, uniq } from "lodash";

import PositionContext from "contexts/PositionContext";

const usePosition = () => {
  const positionContext = useContext(PositionContext);

  if (positionContext === undefined) {
    throw new Error("usePosition should beeing inside a PositionProvider");
  }

  const {
    defenseState,
    attackState,
    setDefenseState,
    setAttackState,
    confirmations,
    currentPosition,
    openAddPosition,
    loading,
    changed,
    setChanged,
  } = positionContext;
  const isDefense = currentPosition === 0;
  const isAttack = currentPosition === 1;
  const kind = isDefense ? "defense" : "attack";

  const mountCharacter = (characterObj) => {
    const {
      id,
      user: { name },
      [kind]: { character, position },
    } = characterObj;

    return {
      id,
      name,
      character,
      position,
    };
  };

  function getAllCharactersFromRole(characterRole) {
    const characters = filter(confirmations, { [kind]: { position: characterRole } });

    return characters;
  }

  function newData(oldPosition, character) {
    const characters = isArray(character) ? character : [character];
    const positionChars = [...(oldPosition ?? []), ...map(characters, mountCharacter)];
    const uniqData = uniqBy(positionChars, "id");

    return uniqData;
  }

  function setPositionState(data) {
    if (!changed) {
      setChanged(true);
    }

    if (isDefense) return setDefenseState((oldDefensePosition) => data(oldDefensePosition));

    return setAttackState((oldAttackPosition) => data(oldAttackPosition));
  }

  function loadCharacters(characterRole, positionName) {
    const characters = getAllCharactersFromRole(characterRole);
    setPositionState((oldData) => ({
      ...oldData,
      [positionName]: newData(oldData?.[positionName] ?? [], characters),
    }));
  }

  function addPosition(positionName) {
    setPositionState((oldData) => ({
      ...oldData,
      [positionName]: [],
    }));
  }

  function removeCharacter(positionName, characterId) {
    setPositionState((oldData) => {
      const newArray = reject(oldData?.[positionName], { id: characterId });

      return {
        ...oldData,
        [positionName]: newArray,
      };
    });
  }

  function addCharacter(positionName, character) {
    setPositionState((oldData) => {
      return {
        ...oldData,
        [positionName]: newData(oldData?.[positionName], character),
      };
    });
  }

  function addTag(positionName, characterId, tagName) {
    setPositionState((oldData) => {
      const newDataObj = { ...oldData };
      const arrayOldPositionNameData = oldData?.[positionName] ?? [];
      const indexOfPosition = findIndex(arrayOldPositionNameData, { id: characterId });
      const character = find(arrayOldPositionNameData, { id: characterId });
      let tags = [...(character?.tags ?? [])];
      if (tags?.includes(tagName)) {
        tags = reject(tags, (tag) => tag === tagName);
      } else {
        tags = uniq([...tags, tagName]);
      }

      const newCharacter = { ...character, tags };
      arrayOldPositionNameData[indexOfPosition] = newCharacter;

      newDataObj[positionName] = arrayOldPositionNameData;
      return newDataObj;
    });
  }

  function editPositionName(positionName, newPositionName) {
    if (positionName !== newPositionName) {
      setPositionState((oldData) => {
        const arrayOldPositionNameData = oldData?.[positionName] ?? [];
        const entries = Object.entries(oldData);
        const indexOfPosition = findIndex(entries, (entry) => entry?.[0] === positionName);
        entries[indexOfPosition] = [newPositionName, arrayOldPositionNameData];
        const newDataObj = {};
        entries.forEach((array) => {
          newDataObj[array?.[0]] = array?.[1] ?? [];
        });
        return newDataObj;
      });
    }
  }

  return useMemo(
    () => ({
      draft: positionContext.draft,
      setLoading: positionContext.setLoading,
      setOpenAddPosition: positionContext.setOpenAddPosition,
      setCurrentPosition: positionContext.setCurrentPosition,
      createDraft: positionContext.createDraft,
      publish: positionContext.publish,
      update: positionContext.update,
      reset: positionContext.reset,
      attackState,
      changed,
      confirmations,
      currentPosition,
      defenseState,
      isAttack,
      kind,
      loading,
      openAddPosition,
      addCharacter,
      addPosition,
      editPositionName,
      addTag,
      getAllCharactersFromRole,
      loadCharacters,
      removeCharacter,
    }),
    [attackState, defenseState, currentPosition, openAddPosition, loading, changed]
  );
};

export default usePosition;
