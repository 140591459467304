import { Link } from "react-router-dom";
import PageLayout from "components/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function NotFound() {
  return (
    <PageLayout>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
        gap={3}
      >
        <MDTypography color="dark" variant="h1">
          404
        </MDTypography>
        <MDTypography color="dark" variant="h3">
          Página não encontrada
        </MDTypography>
        <MDTypography color="dark" component={Link} to="/" variant="h5">
          Voltar para o início
        </MDTypography>
      </MDBox>
    </PageLayout>
  );
}

export default NotFound;
