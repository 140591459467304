import api from "./api";

const list = () => {
  return api.get("/chars");
};

const create = async (data) => {
  const response = await api.post("/chars", data);

  return response;
};

const destroy = async (id) => {
  const response = await api.delete(`/chars/${id}`);

  return response;
};

const characters = { list, create, destroy };

export default characters;
