import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Card, Box, Switch } from "@mui/material";
import { Error } from "@mui/icons-material";

import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import BasicLayout from "layouts/authentication/components/BasicLayout";
import MuiLink from "@mui/material/Link";

import zenMuIcon from "assets/images/icon20243.png";

import useAuth from "hooks/useAuth";

import service from "service";
import { nameRoutes } from "routes";

import bg5 from "assets/images/bg5.webp";

import schema from "./schema";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loadAllData, loadPosition } = useAuth();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleLogin = async (data) => {
    try {
      setLoading(true);
      const response = await service.authentication.login(data, rememberMe);
      await loadPosition();
      loadAllData(response?.data?.user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <BasicLayout image={bg5}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={1}
          mb={4}
          textAlign="center"
        >
          <Box zIndex="1" position="relative" mb={2}>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Bem vindo
            </MDTypography>
          </Box>
          <Box display="flex" justifyContent="center" position="relative" my={1}>
            <Box position="absolute" mt={-1.3} zIndex="0">
              <MDTypography
                component={MuiLink}
                href="https://www.zenmu.com.br"
                variant="body1"
                color="white"
                target="_blank"
              >
                <img width="50px" src={zenMuIcon} alt="zen-mu" />
              </MDTypography>
            </Box>
          </Box>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(handleLogin)}>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="email"
                    label="E-mail"
                    fullWidth
                    error={!!errors?.email}
                  />
                )}
                name="email"
                control={control}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Senha"
                    fullWidth
                    error={!!errors?.password}
                  />
                )}
                name="password"
                control={control}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Permanecer conectado
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton disabled={loading} type="submit" variant="gradient" color="info" fullWidth>
                Entrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Ainda não possui uma conta?{" "}
                <MDTypography
                  disabled={loading}
                  component={Link}
                  to={nameRoutes.signUp}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Registrar-se
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </BasicLayout>
  );
}

export default Basic;
