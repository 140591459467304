import api from "./api";

const confirm = async (data) => {
  const response = await api.post("/up/confirm", data);

  return response;
};
const listConfirmations = async () => {
  const response = await api.get("/up/confirmations");

  return response;
};

const destroy = async (upConfirmationId) => {
  const response = await api.delete(`/up/${upConfirmationId}`);

  return response;
};

const reset = async () => {
  const response = await api.delete("/up");

  return response;
};

const characters = { confirm, listConfirmations, destroy, reset };

export default characters;
