import { useState } from "react";
import { IconButton, Menu } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function AddItem({ handleAddPosition }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MDBox shadow="sm" mt={8}>
      <IconButton
        id="add-item-button"
        sx={(theme) => ({
          width: 50,
          height: 50,
          position: "absolute",
          bottom: 5,
          right: 5,
          color: theme.palette.text.main,
          zIndex: 999,
        })}
        aria-controls={open ? "add-item" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AddCircle
          color="white"
          sx={{
            width: 50,
            height: 50,
          }}
        />
      </IconButton>
      <Menu
        id="add-item"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "28ch",
          },
        }}
      >
        <MDBox px={0.2} gap={1} display="flex" alignItems="center" flexDirection="column">
          <MDButton fullWidth variant="text" onClick={handleAddPosition}>
            Adicionar posição
          </MDButton>
        </MDBox>
      </Menu>
    </MDBox>
  );
}

export default AddItem;
