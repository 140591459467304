import * as yup from "yup";

const schema = yup.object({
  characters: yup.array().of(
    yup.object().shape({
      name: yup.string().min(3).max(12).required().default(""),
      class: yup.object().required(),
    })
  ),
});

export default schema;
