import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { reverse } from "named-urls";

import { isEmpty } from "lodash";
import { Add as AddIcon } from "@mui/icons-material";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import MDButton from "components/MDButton";

import useAdmin from "hooks/useAdmin";

import { useMaterialUIController } from "context";
import service from "service";

import eventsData from "./data/eventsData";

function Events() {
  const navigate = useNavigate();
  const { events, loadEvents, setLoading } = useAdmin();

  const handleEdit = (eventId) => navigate(reverse("/eventos/:eventId", { eventId }));
  const handleCreate = (eventId) => navigate(reverse("/eventos/new", { eventId }));

  const destroy = async (event) => {
    try {
      setLoading(true);
      await service.events.destroy(event?.id);
      await loadEvents();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { columns, rows } = eventsData(events ?? [], destroy, handleEdit);
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    if (isEmpty(events)) {
      loadEvents();
    }
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="sm"
              bgColor={sidenavColor}
              coloredShadow={sidenavColor}
            >
              <MDTypography variant="h6" color="white">
                Todos os eventos
              </MDTypography>
              <MDButton
                size="small"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={handleCreate}
              >
                Novo evento
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Events;
