import api from "./api";

const setToken = (token, keepLogin = false) => {
  const storage = keepLogin ? localStorage : sessionStorage;

  storage.setItem("authToken", token);
};

const login = async (data, keepLogin) => {
  const response = await api.post("/authentication", { ...data, keepLogin });
  setToken(response?.data?.authToken, keepLogin);
  return response;
};

const loggedIn = () => {
  return !!localStorage.getItem("authToken") || !!sessionStorage.getItem("authToken");
};

const logOut = () => {
  localStorage.removeItem("authToken");
  sessionStorage.removeItem("authToken");
};

const authentication = { login, loggedIn, logOut, setToken };

export default authentication;
