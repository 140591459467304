import { Card, Grid } from "@mui/material";

import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import MDBox from "components/MDBox";

import { useMaterialUIController } from "context";

function InactiveUsers({ columns, rows }) {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;

  return (
    <Grid item xs={12}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={2}
          px={2}
          variant="gradient"
          bgColor={sidenavColor}
          borderRadius="sm"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          coloredShadow={sidenavColor}
        >
          <MDTypography variant="h6" color="white">
            Banidos
          </MDTypography>
        </MDBox>
        <MDBox pt={3}>
          <DataTable table={{ columns, rows }} />
        </MDBox>
      </Card>
    </Grid>
  );
}

export default InactiveUsers;
