import { useState } from "react";
import { filter } from "lodash";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

import useAdmin from "hooks/useAdmin";

import service from "service";
import constants from "helpers/constants";

import helpers from "helpers";
import schema from "./schema";

function ViewUser({ open, setOpen, setError, user }) {
  const { loadData } = useAdmin();
  const [loading, setLoading] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      profile: constants.users.PROFILES.find((profile) => profile?.name === user?.profile?.name)
        ?.role,
    },
  });

  const onClose = () => setOpen(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await service.users.updateOne(user?.id, {
        user: {
          ...data,
          profile: constants.users.PROFILES.find((profile) => profile?.role === data?.profile),
        },
      });
      await loadData();
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{user?.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <MDTypography variant="caption">
                <b>Castle Siege</b>{" "}
              </MDTypography>
              <MDBadge
                badgeContent={user?.csConfirmed ? "Confirmado" : "Não confirmado"}
                color={user?.csConfirmed ? "success" : "error"}
                variant="gradient"
                size="sm"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDTypography variant="caption">
                <b>UP Informado</b>{" "}
              </MDTypography>
              <MDBadge
                badgeContent={user?.upConfirmed?.id ? "Sim" : "Não"}
                color={user?.upConfirmed?.id ? "success" : "error"}
                variant="gradient"
                size="sm"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDTypography variant="caption">
                <b>Meta</b>{" "}
              </MDTypography>
              <MDBadge
                badgeContent={user?.meta ? "Pago" : "Não pago"}
                color={user?.meta ? "success" : "error"}
                variant="gradient"
                size="sm"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDTypography variant="caption">
                <b>Último Castle Siege</b>{" "}
              </MDTypography>
              <MDBadge
                badgeContent={user?.lastCsPresence ? "Presente" : "Não presente"}
                color={user?.lastCsPresence ? "success" : "error"}
                variant="gradient"
                size="sm"
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption">
                <b>Último login:</b>{" "}
                {user?.lastLogin ? new Date(user?.lastLogin).toLocaleString() : "Nunca"}
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Nome"
                    variant="standard"
                    helperText={errors?.name?.message}
                    error={!!errors?.name}
                    fullWidth
                  />
                )}
                name="name"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="E-mail"
                    variant="standard"
                    helperText={errors?.email?.message}
                    error={!!errors?.email}
                    fullWidth
                  />
                )}
                name="email"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Whatsapp"
                    variant="standard"
                    helperText={errors?.phone?.message}
                    onChange={(e) => {
                      e.target.value = helpers.phoneMask(e?.target?.value);
                      field.onChange(e);
                      return e;
                    }}
                    error={!!errors?.phone}
                    fullWidth
                  />
                )}
                name="phone"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDSelect
                    {...field}
                    variant="standard"
                    label="Perfil"
                    error={!!errors?.profile}
                    fullWidth
                  >
                    {constants.users.PROFILES.map((item) => (
                      <MenuItem key={item?.role} value={item?.role}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </MDSelect>
                )}
                name="profile"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1.5 }}>
            <MDTypography variant="subtitle1">Personagens: </MDTypography>
            {filter(user?.characters, { status: 0 })?.map((char) => (
              <MDTypography variant="subtitle2">
                {char?.name} - {char?.class?.name}
              </MDTypography>
            ))}
            <MDBox color="error">
              <MDTypography color="inherit" sx={{ mt: 2 }} variant="subtitle1">
                Personagens excluídos:
              </MDTypography>
              {filter(user?.characters, { status: 1 })?.map((char) => (
                <MDTypography color="inherit" variant="subtitle2">
                  {char?.name} - {char?.class?.name}
                </MDTypography>
              ))}
            </MDBox>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton disabled={loading} variant="outlined" color="error" onClick={onClose}>
            Cancelar
          </MDButton>
          <MDButton disabled={loading} variant="contained" color="success" type="submit">
            Salvar
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ViewUser;
