import { useEffect } from "react";

import { useLocation, NavLink, Link as LinkRouterDom } from "react-router-dom";

import PropTypes from "prop-types";

import { List, Divider, Link, Icon, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import SidenavCollapse from "components/Sidenav/SidenavCollapse";

import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import useAuth from "hooks/useAuth";

import service from "service";

import { nameRoutes } from "routes";

import personParadox from "assets/images/person_paradox.png";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { user, permissions } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;
    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName || (key === "home" && collapseName === "")}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName || (key === "home" && collapseName === "")}
          />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  if (!service.authentication.loggedIn()) {
    return null;
  }

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox>
        <MDBox pt={3} pb={1} px={4} textAlign="center">
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox
            component={NavLink}
            to="/"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {brand && (
              <MDBox
                position="relative"
                width="100%"
                height="195px"
                sx={{
                  backgroundImage: `linear-gradient(190deg, rgba(50, 58, 84, 0.4), rgba(26, 32, 53, 0.4)), url(${brand})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  borderRadius: "50%",
                }}
                // src={brand}
                alt="Brand"
              />
            )}
            <MDBox width="100%" sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })} pt={1.5}>
              <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                {brandName}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox>
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
          <List>{renderRoutes}</List>
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={personParadox} alt="profile-image" shadow="md" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {user?.name ?? "-"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {user?.profile?.name ?? "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          {!!permissions?.admin_panel && (
            <Grid item xs={12}>
              <MDButton
                component={LinkRouterDom}
                to={nameRoutes.panel}
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Painel Administrativo
              </MDButton>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
