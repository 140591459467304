import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ComplexStatisticsCard({ color, title, count, action, icon, ...rest }) {
  return (
    <Card {...rest}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          {icon}
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} pb={2}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography variant="h4">{count}</MDTypography>
        </MDBox>
      </MDBox>
      {!!action?.label && (
        <>
          <Divider sx={{ mt: -1 }} />
          <MDBox pb={2} px={2}>
            <MDTypography
              {...(action.action
                ? { onClick: action.action, style: { cursor: "pointer" } }
                : {
                    component: Link,
                    to: action.route,
                  })}
              variant="button"
              color="text"
              display="flex"
            >
              <MDTypography
                component="span"
                variant="button"
                fontWeight="bold"
                color={action.color}
              >
                {">"}
              </MDTypography>
              &nbsp;{action.label}
            </MDTypography>
          </MDBox>
        </>
      )}
    </Card>
  );
}

ComplexStatisticsCard.defaultProps = {
  color: "info",
  action: {
    route: "/",
    color: "success",
    text: "",
    label: "",
  },
};

ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  action: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
