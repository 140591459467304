import { useEffect, useState } from "react";
import { filter, find } from "lodash";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
} from "@mui/material";

import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import ConfirmationForm from "components/ConfirmationForm";

import service from "service";
import useAdmin from "hooks/useAdmin";

function UserConfirmationDialog({ setOpen, open, setError, userId, pos = {} }) {
  const { users, loadConfirmations } = useAdmin();
  const [loading, setLoading] = useState();
  const defaultUser = find(users, { id: userId });
  const [userConfirm, setUserConfirm] = useState();

  useEffect(() => {
    if (defaultUser?.id) {
      setUserConfirm(defaultUser);
    }
  }, [userId]);

  const onClose = () => setOpen(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (defaultUser?.id) {
        await service.castleSiege.editConfirmUser(userConfirm?.id, { data });
      } else {
        await service.castleSiege.confirmUser(userConfirm?.id, { data });
      }
      await loadConfirmations();
      setLoading(false);
      setUserConfirm();
      onClose();
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {defaultUser ? `Atualizar ${defaultUser?.name}` : "Confirmar presença"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!defaultUser && (
          <Box mb={2}>
            <MDSelect
              onChange={(e) => {
                setUserConfirm(find(users, { id: e.target.value }));
                return e;
              }}
              defaultValue=""
              variant="standard"
              label="Membro"
              error={!userConfirm}
              fullWidth
            >
              {filter(users, { csConfirmed: false }).map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </MDSelect>
          </Box>
        )}
        {!!userConfirm && (
          <ConfirmationForm
            pos={pos}
            characters={filter(userConfirm.characters, { status: 0 })}
            user={userConfirm}
            onSubmit={onSubmit}
            formId="confirm-mine"
          />
        )}
      </DialogContent>
      <DialogActions>
        <MDButton disabled={loading} variant="outlined" color="error" onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton
          disabled={loading}
          variant="contained"
          color="success"
          type="submit"
          form="confirm-mine"
        >
          {defaultUser ? "Atualizar presença" : "Confirmar presença"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default UserConfirmationDialog;
