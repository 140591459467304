import { useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";

import useAuth from "hooks/useAuth";

import service from "service";
import constants from "helpers/constants";

import helpers from "helpers";
import schema from "./schema";

function ViewUser({ open, setOpen, setError, user }) {
  const { loadData } = useAuth();
  const [loading, setLoading] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      missionPreference: {
        attack: user?.mission_preference.attack ?? "",
        defense: user?.mission_preference.defense ?? "",
      },
      socials: {
        facebook: user?.socials?.facebook ?? "",
        youtube: user?.socials?.youtube ?? "",
        instagram: user?.socials?.instagram ?? "",
      },
    },
  });

  const onClose = () => setOpen(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await service.users.updateSelf({
        user: data,
      });
      await loadData();
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{user?.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Nome"
                    variant="standard"
                    helperText={errors?.name?.message}
                    error={!!errors?.name}
                    fullWidth
                  />
                )}
                name="name"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="E-mail"
                    variant="standard"
                    helperText={errors?.email?.message}
                    error={!!errors?.email}
                    fullWidth
                  />
                )}
                name="email"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Whatsapp"
                    variant="standard"
                    helperText={errors?.phone?.message}
                    onChange={(e) => {
                      e.target.value = helpers.phoneMask(e?.target?.value);
                      field.onChange(e);
                      return e;
                    }}
                    error={!!errors?.phone}
                    fullWidth
                  />
                )}
                name="phone"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDSelect
                    {...field}
                    variant="standard"
                    label="Missão na defesa"
                    error={!!errors?.missionPreference?.defense}
                    fullWidth
                  >
                    {constants.characters.CHARACTER_ROLES_DEFENSE.map((item) => (
                      <MenuItem key={item?.id} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </MDSelect>
                )}
                name="missionPreference.defense"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDSelect
                    {...field}
                    variant="standard"
                    label="Missão no ataque"
                    error={!!errors?.missionPreference?.attack}
                    fullWidth
                  >
                    {constants.characters.CHARACTER_ROLES_ATTACK.map((item) => (
                      <MenuItem key={item?.id} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </MDSelect>
                )}
                name="missionPreference.attack"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Facebook"
                    variant="standard"
                    helperText={errors?.socials?.facebook?.message}
                    // onChange={(e) => {
                    //   e.target.value = helpers.phoneMask(e?.target?.value);
                    //   field.onChange(e);
                    //   return e;
                    // }}
                    error={!!errors?.socials?.facebook}
                    fullWidth
                  />
                )}
                name="socials.facebook"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Youtube"
                    variant="standard"
                    helperText={errors?.socials?.youtube?.message}
                    // onChange={(e) => {
                    //   e.target.value = helpers.phoneMask(e?.target?.value);
                    //   field.onChange(e);
                    //   return e;
                    // }}
                    error={!!errors?.socials?.youtube}
                    fullWidth
                  />
                )}
                name="socials.youtube"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Instagram"
                    variant="standard"
                    helperText={errors?.socials?.instagram?.message}
                    // onChange={(e) => {
                    //   e.target.value = helpers.phoneMask(e?.target?.value);
                    //   field.onChange(e);
                    //   return e;
                    // }}
                    error={!!errors?.socials?.instagram}
                    fullWidth
                  />
                )}
                name="socials.instagram"
                control={control}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton disabled={loading} variant="outlined" color="error" onClick={onClose}>
            Cancelar
          </MDButton>
          <MDButton disabled={loading} variant="contained" color="success" type="submit">
            Salvar
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ViewUser;
