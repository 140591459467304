import { Box } from "@mui/material";
import { filter, isEmpty } from "lodash";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import personParadox from "assets/images/person_paradox.png";
import Userbar from "components/Userbar";

import constants from "helpers/constants";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function Author({ image, ...user }) {
  const { socialMediaColors } = colors;
  const { size } = typography;
  const socials = Object.entries(user?.socials);

  return (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={user?.name} size="sm" />
      <MDBox ml={2} lineHeight={1} display="flex" flexDirection="column">
        <MDTypography display="block" variant="button" fontWeight="medium">
          {user?.name}
        </MDTypography>
        <MDBox>
          {filter(socials, ([, value]) => !isEmpty(value)).map(([key, value]) => (
            <MDBox
              key={constants.users.DATA_SOCIAL[key]?.color}
              component="a"
              href={value}
              target="_blank"
              rel="noreferrer"
              fontSize={size.lg}
              color={socialMediaColors[constants.users.DATA_SOCIAL[key]?.color]?.main}
              px={0.3}
              lineHeight={1}
            >
              {constants.users.DATA_SOCIAL[key]?.icon}
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

function Characters({ characters, width }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={width}
      gap={2}
      maxHeight="100px"
      sx={{
        overflow: "auto",
      }}
    >
      {characters?.map((character) => (
        <Userbar key={character?.id} character={character} />
      ))}
    </Box>
  );
}

export default function data(users) {
  return {
    columns: [
      { Header: "Nome", align: "left", width: "25%" },
      { Header: "Perfil", align: "left", width: "25%" },
      {
        Header: "Personagens",
        align: "left",
        width: "50%",
      },
    ],
    rows: users?.map((user) => ({
      author: <Author image={personParadox} {...user} />,
      profile: (
        <MDBox display="flex" flexDirection="column" gap={0.5}>
          <MDTypography variant="caption">{user?.profile?.name}</MDTypography>
          <MDTypography variant="caption">
            <b>Missão no ataque:</b> {user?.mission_preference?.attack}
          </MDTypography>
          <MDTypography variant="caption">
            <b>Missão na defesa:</b> {user?.mission_preference?.defense}
          </MDTypography>
        </MDBox>
      ),
      characters: (
        <Characters
          characters={user?.characters}
          width={user?.characters?.length > 2 ? "100%" : "calc(100% - 20px)"}
        />
      ),
      key: user?.id,
    })),
  };
}
